import { IConfig } from './types';

const Config: IConfig = {
  NAME: 'Plenty Defi',
  API: {
    url: 'https://api.coingecko.com/api/v3/coins/tezos?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false',
    API_KEY: '4824FE50-DB6E-4316-B099-72283C964891',
    tezToolTokenPrice: 'https://api.teztools.io/token/prices',
    indexerPrice: 'https://analytics-indexer.plenty.network/analytics/tokens',
  },
  RPC_NODES: {
    testnet: 'https://ghostnet.smartpy.io/',
    mainnet: process.env.REACT_APP_PLENTY_RPC_NODE || 'https://rpc.tzkt.io/mainnet/',
  },
  TZKT_NODES: {
    mainnet: 'https://api.tzkt.io',
    testnet: process.env.REACT_APP_TZKT_GHOSTNET || 'https://api.ghostnet.tzkt.io',
  },
  TOKENS_PAGE: {
    mainnet: 'https://indexer.plentydefi.com/',
    testnet: 'https://indexer.plentydefi.com/',
  },
  CTEZ: {
    mainnet: 'KT1GWnsoFZVHGh7roXEER3qeCcgJgrXT3de2',
    testnet: 'KT19xSuHb2A86eSbKVsduY8mZv4UVEBPwQ17',
  },
  STAKING_CONTRACTS: {
    POOLS: {
      mainnet: {
        PLENTY: {
          active: [],
          inactive: [
            {
              address: 'KT1QqjR4Fj9YegB37PQEqXUPHmFbhz6VJtwE',
              mapId: 4494,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1UDe1YP963CQSb5xN7cQ1X8NJ2pUyjGw5T',
              mapId: 3949,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        USDtz: {
          active: [],
          inactive: [
            {
              address: 'KT1K5cgrw1A8WTiizZ5b6TxNdFnBq9AtyQ7X',
              mapId: 3953,
              decimal: 18,
              tokenDecimal: 6,
            },
            {
              address: 'KT1MBqc3GHpApBXaBZyvY63LF6eoFyTWtySn',
              mapId: 4490,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        ETHtz: {
          active: [],
          inactive: [
            {
              address: 'KT1J7v85udA8GnaBupacgY9mMvrb8zQdYb3E',
              mapId: 3950,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT19asUVzBNidHgTHp8MP31YSphooMb3piWR',
              mapId: 4491,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        hDAO: {
          active: [],
          inactive: [
            {
              address: 'KT1Vs8gqh7YskPnUQMfmjogZh3A5ZLpqQGcg',
              mapId: 3952,
              decimal: 18,
              tokenDecimal: 6,
            },
            {
              address: 'KT1Ga15wxGR5oWK1vBG2GXbjYM6WqPgpfRSP',
              mapId: 4496,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        wLINK: {
          active: [],
          inactive: [
            {
              address: 'KT1JCkdS3x5hTWdrTQdzK6vEkeAdQzsm2wzf',
              mapId: 3948,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1KyxPitU1xNbTriondmAFtPEcFhjSLV1hz',
              mapId: 4492,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        wMATIC: {
          active: [],
          inactive: [
            {
              address: 'KT1TNzH1KiVsWh9kpFrWACrDNnfK4ihvGAZs',
              mapId: 3947,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1XherecVvrE6X4PV5RTwdEKNzA294ZE9T9',
              mapId: 4493,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        WRAP: {
          active: [],
          inactive: [
            {
              address: 'KT18oB3x8SLxMJq2o9hKNupbZZ5ZMsgr2aho',
              mapId: 7988,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
      },
    },
    FARMS: {
      mainnet: {
        'PLENTY - XTZ': {
          active: [
            {
              address: 'KT1JQAZqShNMakSNXc2cgTzdAWZFemGcU6n1',
              mapId: 4503,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [
            {
              address: 'KT1BfQLAsQNX8BjSBzgjTLx3GTd3qhwLoWNz',
              mapId: 3962,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'CTEZ - DOGA': {
          active: [
            {
              address: 'KT1E6qsUB3EKEDs4S3yQCyr39zVjsjBtFjEp',
              mapId: 171836,
              decimal: 18,
              tokenDecimal: 5,
            },
          ],
          inactive: [],
        },
        'kUSD - USDC.e': {
          active: [
            {
              address: 'KT1HpncMNCYt1ZskHzZeFqHFgfM8ZEr8xd3f',
              mapId: 181977,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'uUSD - USDC.e': {
          active: [
            {
              address: 'KT1GQ7UTHFA8gTH3uTzDnb1znDDBfJ7eXCUJ',
              mapId: 181975,
              decimal: 18,
              tokenDecimal: 9,
            },
          ],
          inactive: [],
        },
        'USDtz - USDC.e': {
          active: [
            {
              address: 'KT1BUqUTY4r8ByQ5psHeQS46zuXu7JUvvWHH',
              mapId: 193664,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [],
        },
        'EURL - agEUR.e': {
          active: [
            {
              address: 'KT1Ne85tY7r6HuyRExQNb2V5zzE9u4ppts6m',
              mapId: 210601,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'USDt - ctez': {
          active: [
            {
              address: 'KT1DkNdbMDy3bZcFwJQi71B6Lb6H3VmSjAx5',
              mapId: 245046,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [],
        },

        'WMATIC.p - ctez': {
          active: [
            {
              address: 'KT1XAPCdjYzt5Q1SRrUqM8ZC4fekasgbEnju',
              mapId: 331110,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },

        'WETH.p - ctez': {
          active: [
            {
              address: 'KT1QCt7fgdTRr6EMKTqVE7F2JLLQgdvQUTL1',
              mapId: 334552,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'WMATIC.p - MATIC.e': {
          active: [
            {
              address: 'KT1Eq23cXLKnaGPPkR7hdMHV44GHX356L9Xj',
              mapId: 331109,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },

        'WETH.p - WETH.e': {
          active: [
            {
              address: 'KT1VQBmo7brLt5AR6nx39stzP677mR7s52Mv',
              mapId: 334546,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },

        'BUSD.e - USDC.e': {
          active: [
            {
              address: 'KT1HK91NY9uWa685DAYRH8ywNMaN52D8Z8NS',
              mapId: 244552,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'kUSD - USDt': {
          active: [
            {
              address: 'KT1EQ9RzjVNozWTTbuHPFsoaHDFiP4nMBRGA',
              mapId: 231218,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'uUSD - USDt': {
          active: [
            {
              address: 'KT1BQgGMe4PECmbFK1kyEdJmoXyJeCWf8XzC',
              mapId: 231220,
              decimal: 18,
              tokenDecimal: 9,
            },
          ],
          inactive: [],
        },
        'USDT.e - USDC.e': {
          active: [
            {
              address: 'KT1PJJ7JgjJyc5VzHz2kPfUikauzt6r1QfJw',
              mapId: 198863,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [],
        },
        'tzBTC - WBTC.e': {
          active: [
            {
              address: 'KT1SzTf3uBZAx1DnPbNTg29H7dVPbuzkGJNo',
              mapId: 181060,
              decimal: 18,
              tokenDecimal: 8,
            },
          ],
          inactive: [],
        },
        'DAI.e - USDC.e': {
          active: [
            {
              address: 'KT1Kx45mQaZMEtFrbd6ap814NoH7cQTqo33j',
              mapId: 188325,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'ETHtz - WETH.e': {
          active: [
            {
              address: 'KT18qMYMqPv8J17wNoQigD1C66eyhf9b5s2e',
              mapId: 188326,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'WBTC.e - CTEZ': {
          active: [
            {
              address: 'KT1CZkFknknf2NdV48VMRw9cp97n9T7oRApi',
              mapId: 178082,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
          inactive: [],
        },
        'USDC.e - CTEZ': {
          active: [
            {
              address: 'KT1P3MUj7ZY1pak1szf41vxtuqz7AVMgCLhj',
              mapId: 178076,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [],
        },
        'EURL - CTEZ': {
          active: [
            {
              address: 'KT1XaHdsQAh4EZkjgvwRFBkXpqh6rFAP2cXM',
              mapId: 281319,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [],
        },
        'agEUR.e - USDC.e': {
          active: [
            {
              address: 'KT1JZPj4AwDSNNfmjWNJU3qqxobdAwt1KS1b',
              mapId: 281326,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'WETH.e - CTEZ': {
          active: [
            {
              address: 'KT1U36NnQrpQ2vVHde6PtTPKEpNXHrEZ84ek',
              mapId: 188312,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'MATIC.e - CTEZ': {
          active: [
            {
              address: 'KT1RvJGhrRf3D5ymy1jkof6wvqE57u2qc6J1',
              mapId: 188313,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'LINK.e - CTEZ': {
          active: [
            {
              address: 'KT1CGj3hNZniZELy2VREDYyTpYEfvJsqKQ6a',
              mapId: 188314,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'KALAM - XTZ': {
          active: [],
          inactive: [
            {
              address: 'KT1DjDZio7k2GJwCJCXwK82ing3n51AE55DW',
              mapId: 4488,
              decimal: 18,
              tokenDecimal: 10,
            },
          ],
        },
        'PLENTY - wBUSD': {
          active: [],
          inactive: [
            {
              address: 'KT1KJhxkCpZNwAFQURDoJ79hGqQgSC9UaWpG',
              mapId: 10768,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - wUSDC': {
          active: [],
          inactive: [
            {
              address: 'KT1Kp3KVT4nHFmSuL8bvETkgQzseUYP3LDBy',
              mapId: 11019,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'PLENTY - wWBTC': {
          active: [],
          inactive: [
            {
              address: 'KT1M82a7arHVwcwaswnNUUuCnQ45xjjGKNd1',
              mapId: 11057,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
        },
        'PLENTY - wMATIC': {
          active: [],
          inactive: [
            {
              address: 'KT1UP9XHQigWMqNXYp9YXaCS1hV9jJkCF4h4',
              mapId: 11823,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - wLINK': {
          active: [],
          inactive: [
            {
              address: 'KT1UqnQ6b1EwQgYiKss4mDL7aktAHnkdctTQ',
              mapId: 11819,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - USDtz': {
          active: [
            {
              address: 'KT1VCrmywPNf8ZHH95HKHvYA4bBQJPa8g2sr',
              mapId: 11821,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - hDAO': {
          active: [],
          inactive: [
            {
              address: 'KT1W3DtcPXbD7MMmtUdk3F352G6CYFSpwUUS',
              mapId: 13080,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'PLENTY - ETHtz': {
          active: [],
          inactive: [
            {
              address: 'KT1EVfYFoSpte3PnE4tPoWuj1DhNPVQwrW5Y',
              mapId: 13081,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - wWETH': {
          active: [],
          inactive: [
            {
              address: 'KT1CBh8BKFV6xAH42hEdyhkijbwzYSKW2ZZC',
              mapId: 13082,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - kUSD': {
          active: [
            {
              address: 'KT1MmAy4mSbZZVzPoYbK3M4z3GWUo54UTiQR',
              mapId: 13083,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - QUIPU': {
          active: [],
          inactive: [
            {
              address: 'KT1FsMiweyRTog9GGNC22hiMTFVRPrGs3eto',
              mapId: 13085,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'PLENTY - WRAP': {
          active: [],
          inactive: [
            {
              address: 'KT1K9kLuhq9AJjDAgbJdKGBiP9927WsRnjP6',
              mapId: 14292,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
        },
        'PLENTY - UNO': {
          active: [],
          inactive: [
            {
              address: 'KT1CWNVmHs6RRbLzwA3P19h7Wa9smnDrAgpS',
              mapId: 14293,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
        },
        'PLENTY - SMAK': {
          active: [],
          inactive: [
            {
              address: 'KT1VwZPZ4bcPQYS1C4yRvmU4giQDXhEV81WD',
              mapId: 14299,
              decimal: 18,
              tokenDecimal: 10,
            },
          ],
        },
        'PLENTY - KALAM': {
          active: [],
          inactive: [
            {
              address: 'KT1UTvMuyRggQe9q1hrh7YLh7vxffX2egtS6',
              mapId: 14295,
              decimal: 18,
              tokenDecimal: 14,
            },
          ],
        },
        'PLENTY - tzBTC': {
          active: [
            {
              address: 'KT1RwFV1xQU2E9TsXe1qzkdwAgFWaKk8bfAa',
              mapId: 14291,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
          inactive: [],
        },
        'PLENTY - uUSD': {
          active: [
            {
              address: 'KT1HSYQ9NLTQufuvNUwMhLY7B9TX8LDUfgsr',
              mapId: 15911,
              decimal: 18,
              tokenDecimal: 14,
            },
          ],
          inactive: [],
        },
        'PLENTY - GIF': {
          active: [],
          inactive: [
            {
              address: 'KT1UH21n4iwXu7gGrh34RKZfRewpcgQLdbXq',
              mapId: 17071,
              decimal: 18,
              tokenDecimal: 13,
              dualInfo: {
                tokenFirst: {
                  symbol: 'PLENTY',
                  tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                  tokenDecimal: 18,
                  tokenType: 'FA1.2',
                  tokenId: 0,
                  rewardContract: 'KT1KPSKAsmM9k28Lsbk1mmrujHpCnQHxKhKS',
                  rewardMapId: 17068,
                },
                tokenSecond: {
                  symbol: 'GIF',
                  tokenContract: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
                  tokenDecimal: 9,
                  tokenType: 'FA2',
                  tokenId: 0,
                  rewardContract: 'KT1LQS3kyhaaW6thmW96anY71gZ92ECGU7Ja',
                  rewardMapId: 17069,
                },
              },
            },
          ],
        },

        'CTEZ - TEZ': {
          active: [
            {
              address: 'KT1WzUmmF98aQdpKnWigkg3SnJiL1s2Fj6QQ',
              mapId: 171752,
              decimal: 6,
              tokenDecimal: 6,
              dualInfo: {
                tokenFirst: {
                  symbol: 'PLENTY',
                  tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                  tokenDecimal: 18,
                  tokenType: 'FA1.2',
                  tokenId: 0,
                  rewardContract: 'KT1QkadMTUTDxyNiTaz587ssPXFuwmWWQzDG',
                  rewardMapId: 171750,
                },
                tokenSecond: {
                  symbol: 'TEZ',
                  tokenContract: '',
                  tokenDecimal: 6,
                  tokenType: 'XTZ',
                  tokenId: 0,
                  rewardContract: 'KT1PxZCPGoxukDXq1smJcmQcLiadTB6czjCY',
                  rewardMapId: 171751,
                },
              },
            },
          ],
          inactive: [],
        },
        'PLENTY - YOU': {
          active: [],
          inactive: [
            {
              address: 'KT1MkXtVBuCKtxqSh7APrg2d7ThGBmEf4hnw',
              mapId: 20167,
              decimal: 18,
              tokenDecimal: 15,
            },
          ],
        },
        'PLENTY - wDAI': {
          active: [],
          inactive: [
            {
              address: 'KT1FJzDx9AwbuNHjhzQuUxxKUMA9BQ7DVfGn',
              mapId: 20166,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        'PLENTY - wUSDT': {
          active: [],
          inactive: [
            {
              address: 'KT1S4XjwGtk55TmsMqSdazEMrH4pGA3NMXhz',
              mapId: 20160,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'PLENTY - cTez': {
          active: [
            {
              address: 'KT1MfMMsYX34Q9cEaPtk4qkQ6pojA7D2nsgr',
              mapId: 21513,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'uUSD - YOU': {
          active: [],
          inactive: [
            {
              address: 'KT1KGKzNGX1NDP3hGcipzyqVMCkwWbH76NJU',
              mapId: 22800,
              decimal: 12,
              tokenDecimal: 12,
            },
          ],
        },
        'uUSD - wUSDC': {
          active: [],
          inactive: [
            {
              address: 'KT1DfYVe4CaE9S6Sm3SEfhFYVZ9XzJbkQDqs',
              mapId: 22814,
              decimal: 12,
              tokenDecimal: 9,
            },
          ],
        },
        'uUSD - uDEFI': {
          active: [],
          inactive: [
            {
              address: 'KT1RENb4rWNFPP5QJSYT4rRGGsk1tPgLLwu2',
              mapId: 22813,
              decimal: 12,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - kUSD': {
          active: [],
          inactive: [
            {
              address: 'KT1L8N5RZg4CM2VSnuC8t1CGLiQpzVoN6P1u',
              mapId: 46410,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - wUSDC': {
          active: [],
          inactive: [
            {
              address: 'KT1V71rxAhuHCggnBUXfceBM6RpHzUUJMYyK',
              mapId: 46429,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - KALAM': {
          active: [],
          inactive: [
            {
              address: 'KT1HmigpaNyaxx9Zvf7zEPGgqd8bdLTnrgdU',
              mapId: 46443,
              decimal: 18,
              tokenDecimal: 8,
            },
          ],
        },
        'ctez - USDtz': {
          active: [],
          inactive: [
            {
              address: 'KT1MeeKpbSaAP9YDb6B7qBDvEGZq274715s2',
              mapId: 46427,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - wUSDT': {
          active: [],
          inactive: [
            {
              address: 'KT1PYtm173zVdPEMoNecUBRhqgFbdVHPDj3p',
              mapId: 46428,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - wBUSD': {
          active: [],
          inactive: [
            {
              address: 'KT1MCtUoNZTpGsPNyEvCZ88i6aRVow91axPr',
              mapId: 46426,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - wDAI': {
          active: [],
          inactive: [
            {
              address: 'KT1ELuj8v8nB3Me7dEyjoxkNtjr6ywWXFWYK',
              mapId: 46425,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - GIF': {
          active: [],
          inactive: [
            {
              address: 'KT1BaDfS92GJRDpBhrZh2a29ZyNmEjaYApkK',
              mapId: 46445,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - tzBTC': {
          active: [],
          inactive: [
            {
              address: 'KT1SQ4fqsxrKwN5mqCi2yNLvb99S6sRGWGZZ',
              mapId: 47097,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - PAUL': {
          active: [],
          inactive: [
            {
              address: 'KT1K3NERNpLxRmREpmjqt7LsvEr2SbC9Cs6o',
              mapId: 47090,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - INSTA': {
          active: [],
          inactive: [
            {
              address: 'KT1UwhcK52Pk3VsFyFmFocVzSmKY8wRHGdDC',
              mapId: 47091,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - ETHtz': {
          active: [],
          inactive: [
            {
              address: 'KT1U2XwrY1oTfxzrcb1ZrQuFrUJTQWAJaHWt',
              mapId: 46416,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - QUIPU': {
          active: [],
          inactive: [
            {
              address: 'KT1L71FDbzYwe9bxY4MnzU3ZUgUzbe3V588Y',
              mapId: 46446,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - hDAO': {
          active: [],
          inactive: [
            {
              address: 'KT1BZ8Napzts13b9kLGBWCwhMmx6zmAzRMkG',
              mapId: 46447,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - kDAO': {
          active: [],
          inactive: [
            {
              address: 'KT1DornVnLZWu3VaJnDLB2RubSFSPBZaKq4W',
              mapId: 46423,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - wWETH': {
          active: [],
          inactive: [
            {
              address: 'KT1JfEmUp9w2n9aWJweKpEWAESRjrCywcW3M',
              mapId: 46417,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
        },
        'ctez - CRUNCH': {
          active: [],
          inactive: [
            {
              address: 'KT1CoCHHKAUE8vobDwaWQDDBzrBkasCuzkwi',
              mapId: 47083,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - UNO': {
          active: [],
          inactive: [
            {
              address: 'KT1Xt9HvkrbzYtaYhD9im37hE69Zit5bvhPw',
              mapId: 46441,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - WRAP': {
          active: [],
          inactive: [
            {
              address: 'KT1LPhjjaFaqcgynzewegLckJUmpQQzm1xj2',
              mapId: 46438,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - PXL': {
          active: [],
          inactive: [
            {
              address: 'KT1HpcPqU2atG75bpSy6hUzuWdULi7Kx9eaw',
              mapId: 46440,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - crDAO': {
          active: [],
          inactive: [
            {
              address: 'KT1BfAAVACnEAb3CMMxPLb1VdDnyuNq5AWEc',
              mapId: 46442,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
        'ctez - SMAK': {
          active: [],
          inactive: [
            {
              address: 'KT1MSWvuDNcjnxP3mBK4mePLpVLfrjZ3i2nH',
              mapId: 46544,
              decimal: 18,
              tokenDecimal: 4,
            },
          ],
        },
        'ctez - FLAME': {
          active: [],
          inactive: [
            {
              address: 'KT1HsdhTQqha8k6WrvhD9d6CQhHGb3XCtLub',
              mapId: 46450,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'ctez - uUSD': {
          active: [],
          inactive: [
            {
              address: 'KT1VutBNeCNEHtk6jVkY8v3FE848GNsxjT76',
              mapId: 46449,
              decimal: 18,
              tokenDecimal: 9,
            },
          ],
        },
        'ctez - wWBTC': {
          active: [],
          inactive: [
            {
              address: 'KT1VmvPo8bLYh1xVC9TpzwWtrczjzEMS2mEk',
              mapId: 46439,
              decimal: 18,
              tokenDecimal: 7,
            },
          ],
        },
      },
    },
    PONDS: {
      mainnet: {
        WRAP: {
          active: [],
          inactive: [
            {
              address: 'KT1GotpjdBaxt2GiMFcQExLEk9GTfYo4UoTa',
              mapId: 7985,
              decimal: 8,
              tokenDecimal: 18,
            },
          ],
        },
        KALAM: {
          active: [],
          inactive: [
            {
              address: 'KT1WfLprabHVTnNhWFigmopAduUpxG5HKvNf',
              mapId: 5137,
              decimal: 10,
              tokenDecimal: 18,
            },
          ],
        },
      },
    },
  },
  TOKEN_CONTRACTS: {
    testnet: {
      PLENTY: {
        address: 'KT1Mdk7TfHjbwb2ciTTsySj9gV9W9uc7HxCu',

        mapId: 33509,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
    },
    mainnet: {
      PLENTY: {
        address: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        mapId: 3943,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      xPLENTY: {
        address: 'KT1Rpviewjg82JgjGfAKFneSupjAR1kUhbza',
        mapId: 18153,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      wMATIC: {
        address: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        decimal: 18,
        type: 'FA2',
        tokenId: 11,
      },
      wLINK: {
        address: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        decimal: 18,
        type: 'FA2',
        tokenId: 10,
      },
      DOGA: {
        address: 'KT1Ha4yFVeyzw6KRAdkzq6TxDHB97KG4pZe8',
        mapId: 134335,
        decimal: 5,
        type: 'FA1.2',
        tokenId: 0,
      },
      USDtz: {
        address: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
        mapId: 36,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      ETHtz: {
        address: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
        mapId: 199,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      hDAO: {
        address: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
        mapId: 515,
        decimal: 6,
        type: 'FA2',
        tokenId: 0,
      },
      WRAP: {
        address: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
        mapId: 1777,
        decimal: 8,
        type: 'FA1.2',
        tokenId: 0,
      },
      KALAM: {
        address: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
        mapId: 4178,
        decimal: 10,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - XTZ': {
        address: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
        mapId: 3956,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'CTEZ - DOGA': {
        address: 'KT1DZ41c1mV12oh8YNXm54JpwUNZ2C5R6VaG',
        mapId: 171789,
        decimal: 5,
        type: 'FA1.2',
        tokenId: 0,
      },
      'kUSD - USDC.e': {
        address: 'KT1J5HNUXnQwonSam3kmDQ5WbhynPPpVtgdN',
        mapId: 181898,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - USDC.e': {
        address: 'KT1Ah4Fe2hjcRdVCiCQDhRDZ2vDYLdYNjY51',
        mapId: 181890,
        decimal: 9,
        type: 'FA1.2',
        tokenId: 0,
      },
      'USDtz - USDC.e': {
        address: 'KT1P9GyfYrW9EZ8m3jej2cMu49fpmEbYa2h5',
        mapId: 193185,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },

      'kUSD - USDt': {
        address: 'KT1JaYzZooZmq9JP9FScjLShbUco8Pn8Ckct',
        mapId: 231140,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },

      'uUSD - USDt': {
        address: 'KT1Doix5iZZsQzKY3ANDkoca6GfCtJbXY9sk',
        mapId: 231143,
        decimal: 9,
        type: 'FA1.2',
        tokenId: 0,
      },

      'EURL - agEUR.e': {
        address: 'KT1X4fkZ1f3uCpBqC1HZemsSNFbqm6bkpgfb',
        mapId: 210417,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },

      'USDt - ctez': {
        address: 'KT1BaGrWEPYsEyQEftyegLrGEqY2psAJh5GX',
        mapId: 244529,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WMATIC.p - ctez': {
        address: 'KT1FCXZu12H6G5Ujw8jPdektkuW5kWHEn49D',
        mapId: 331071,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WETH.p - ctez': {
        address: 'KT1B5MPrS2KTTJ3VufVsr1RyMxuY1k5nhSdY',
        mapId: 333922,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WMATIC.p - MATIC.e': {
        address: 'KT1VBQbNYvD5VGRRMfgovNehtoevXeTMyQWm',
        mapId: 331074,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WETH.p - WETH.e': {
        address: 'KT1JEwoaaCHwof9ERqqZzpSuKXwwgCwV6bhV',
        mapId: 333925,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },

      'BUSD.e - USDC.e': {
        address: 'KT1G76qEBDqmAXLCCVg4ZuNbrENsxFe8aECe',
        mapId: 193188,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },

      'USDT.e - USDC.e': {
        address: 'KT1CDeAxaiqbA5aMkPMmqqYXxqgfFwocJHza',
        mapId: 198730,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'tzBTC - WBTC.e': {
        address: 'KT1HJbndQjBEhAqR3Vj3NaP3RwFGudJf5YP4',
        mapId: 179610,
        decimal: 8,
        type: 'FA1.2',
        tokenId: 0,
      },
      'DAI.e - USDC.e': {
        address: 'KT1TupjqgajsoArMKK3ifmNveKhU2XpTJrH8',
        mapId: 187493,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ETHtz - WETH.e': {
        address: 'KT19ga4Vz26GRBdn8oibR4WFF7aDF4oE9Ch3',
        mapId: 187480,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WBTC.e - CTEZ': {
        address: 'KT1VRAYxpCo5xHYmBLYmMAWxXoVYCVgpeuak',
        mapId: 177398,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'USDC.e - CTEZ': {
        address: 'KT1XpLzv4tqqsf2aLneDZgEeNFhKkH8JBGXv',
        mapId: 177380,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'EURL - CTEZ': {
        address: 'KT1HFMKcdrLBH5RjC4waPVNprn8aywDEgwog',
        mapId: 280368,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'agEUR.e - USDC.e': {
        address: 'KT1CDK8VMGy94sH4Mb6YzMmJX7HTsFqYTFee',
        mapId: 280378,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'WETH.e - CTEZ': {
        address: 'KT1SDugcNjEbNQe55TdQppiRP1wVDbmxHuF1',
        mapId: 188174,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'MATIC.e - CTEZ': {
        address: 'KT18rVWWn2LMo2Hpy6KNhkhGgks21g11dxVv',
        mapId: 188159,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'LINK.e - CTEZ': {
        address: 'KT1WcS1cdwcbT3yyuYA6uzsYdZxBax5cGjdR',
        mapId: 188165,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },

      'KALAM - XTZ': {
        address: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
        mapId: 4353,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wBUSD': {
        address: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
        mapId: 10749,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wUSDC': {
        address: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
        mapId: 10755,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wWBTC': {
        address: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
        mapId: 11051,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wMATIC': {
        address: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
        mapId: 11807,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wLINK': {
        address: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
        mapId: 11811,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - USDtz': {
        address: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
        mapId: 11814,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - hDAO': {
        address: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
        mapId: 12952,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - ETHtz': {
        address: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
        mapId: 12955,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wWETH': {
        address: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
        mapId: 12959,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - kUSD': {
        address: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
        mapId: 12963,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - QUIPU': {
        address: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
        mapId: 12966,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - WRAP': {
        address: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
        mapId: 14096,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - UNO': {
        address: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
        mapId: 14100,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - SMAK': {
        address: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
        mapId: 14107,
        decimal: 10,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - KALAM': {
        address: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
        mapId: 14104,
        decimal: 14,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - tzBTC': {
        address: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
        mapId: 14093,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - uUSD': {
        address: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
        mapId: 15796,
        decimal: 14,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - GIF': {
        address: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
        mapId: 17061,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'CTEZ - TEZ': {
        address: 'KT1DMnJvNrFYc8N9Ptxhw3NtqKN7AWqxCpkS',
        mapId: 171168,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - YOU': {
        address: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
        mapId: 20020,
        decimal: 15,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wDAI': {
        address: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
        mapId: 20016,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wUSDT': {
        address: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
        mapId: 20024,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - cTez': {
        address: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
        mapId: 21490,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - wUSDC': {
        address: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
        mapId: 22767,
        decimal: 9,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - YOU': {
        address: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
        mapId: 22770,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - uDEFI': {
        address: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
        mapId: 22802,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - kUSD': {
        address: 'KT19bFNh8pZCRtTy7Pi9kpEcb2Yd7KWArAik',
        mapId: 44750,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - USDtz': {
        address: 'KT1DQfEUBsaZo43G3j1CbFF9BiWZXS72DuCh',
        mapId: 44781,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wUSDC': {
        address: 'KT19bFNh8pZCRtTy7Pi9kpEcb2Yd7KWArAik',
        mapId: 44803,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wUSDT': {
        address: 'KT1KgCx6CYVSyLxfiFdo5hVuMwZBPQy1XZaf',
        mapId: 44806,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wBUSD': {
        address: 'KT1K7GRKUWzfNJ9jzGprb13XDVqqp6GE67ZU',
        mapId: 44819,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wDAI': {
        address: 'KT1VLLdUaMfFbJw87b1PaVG1ac6UYM5xzqC5',
        mapId: 44829,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - KALAM': {
        address: 'KT1N3mKQMnWEwt4FB3J4qP2X8LwYgndGDZrn',
        mapId: 44834,
        decimal: 8,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - GIF': {
        address: 'KT1KdPxrn7UTx6CUWxuUBaGx4bVv6ZJKWUWr',
        mapId: 44857,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - ETHtz': {
        address: 'KT1GAvdLT9tkpKkRmkuChuL9T83esBtatgKm',
        mapId: 44870,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - QUIPU': {
        address: 'KT1C6Q4Mnnpns8vSQy7kJitZNMsED24SMw9m',
        mapId: 44884,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - hDAO': {
        address: 'KT1DidqBuSaxGbVaDq2gLVkfh262dYyoGeSH',
        mapId: 44890,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - kDAO': {
        address: 'KT1KmkH1T2CQV2Q5AcMeZy3GHhwNac48FBap',
        mapId: 44912,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wWETH': {
        address: 'KT1BAiXZkKG4R9W7LAKiAQahC8Wg7ZpJBaEs',
        mapId: 44916,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - uUSD': {
        address: 'KT1BX8F7Xrtsy1tJ91iLovu4nJ8ZyVv4Dac2',
        mapId: 44924,
        decimal: 9,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - FLAME': {
        address: 'KT1UfvQiYexnpag6rZ3LMGsn5YFiEHrXrtbq',
        mapId: 44943,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - SMAK': {
        address: 'KT1NeR3ExLddfdAbs5nwpQMm6DqBekyJTc4R',
        mapId: 44946,
        decimal: 4,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - crDAO': {
        address: 'KT1MQcJCLZL8ASHYGmT5LVzeXV64ruy7RAuH',
        mapId: 44954,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - PXL': {
        address: 'KT1GhzTxzgXgdv1rRCppAB5D4poXiUgp8yoU',
        mapId: 44994,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - UNO': {
        address: 'KT1WKWhTNMY4h4eHunbsnJ1oAtDWXCz4qNQm',
        mapId: 45316,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - WRAP': {
        address: 'KT1MX73dim8HKqQLjTHvkJnpRUp37C1HXdkt',
        mapId: 44767,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - wWBTC': {
        address: 'KT1XWRUcvtcgpsETcasdUnNkq9rJeWmz8ihD',
        mapId: 44790,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - tzBTC': {
        address: 'KT1TMZjKBvSVKzETZTyRsTxYEu4uZLLs6VpJ',
        mapId: 44904,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - PAUL': {
        address: 'KT1N93veCzepAPydFGGUhDFiA69PoawasxXi',
        mapId: 46392,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - CRUNCH': {
        address: 'KT1QSA2SMDEKzEnJhGrVGheWb94Ux8Wyfuy6',
        mapId: 44763,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - INSTA': {
        address: 'KT1GkTwzN2gR6UdBicDSu2JEaRtv5KAPunrj',
        mapId: 44957,
        decimal: 7,
        type: 'FA1.2',
        tokenId: 0,
      },
      'ctez - DOGA': {
        address: 'KT1DZ41c1mV12oh8YNXm54JpwUNZ2C5R6VaG',
        mapId: 171789,
        decimal: 5,
        type: 'FA1.2',
        tokenId: 0,
      },
    },
  },
  SERVERLESS_BASE_URL: {
    mainnet: 'https://6vst806xp9.execute-api.us-east-2.amazonaws.com/v1',
    testnet: 'https://testnet.dummy-api.us-east-10.amazonaws.com/v1',
  },
  SERVERLESS_REQUEST: {
    mainnet: {
      'HOME-PAGE-TVL': '/tvl',
      'PLENTY-STATS': '/homestats',
    },
    testnet: {},
  },
  ROUTER: {
    mainnet: 'KT1MEVCrGRCsoERXf6ahNLC4ik6J2vRH7Mm6',
    testnet: 'KT1FMZVMeyCrNFyGjJfiB6VSq3LX1ShSEzuw',
  },
  STABLESWAP: {
    testnet: {
      ctez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HZW9FWJt6aU8x4nr6UiBry2eUCA7xEFb1',
        mapId: 26976,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          tez: {
            contract: 'KT1AD4khq2R9q6DDbRY5CvUk8MXoshqsatGS',
            property: 'ctezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },
      tez: {
        ICON: '',
        TOKEN_CONTRACT: '',
        READ_TYPE: 'XTZ',
        CALL_TYPE: 'XTZ',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1AD4khq2R9q6DDbRY5CvUk8MXoshqsatGS',
            property: 'tezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },
      'ctez-XTZ-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Rp1fLJPFiR3w5iYSB1zxz4aDWL3Biiqhy',
        mapId: 80419,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {},
      },
    },
    mainnet: {
      ctez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SjXiUX63QvdNMcM2m492f7kuf8JxXRLp4',
        mapId: 20920,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          tez: {
            contract: 'KT1CAYNQGvYSF5UvHK21grMrKpe2563w9UcX',
            property: 'ctezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },

      tez: {
        ICON: '',
        TOKEN_CONTRACT: '',
        READ_TYPE: 'XTZ',
        CALL_TYPE: 'XTZ',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1CAYNQGvYSF5UvHK21grMrKpe2563w9UcX',
            property: 'tezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },
      uUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
        mapId: 7706,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1Ji4hVDeQ5Ru7GW1Tna9buYSs3AppHLwj9',
            property: 'token2_pool',
            liquidityToken: 'usdc.e-uusd-LP',
            type: 'veStableAMM',
          },
          USDt: {
            contract: 'KT19XPttmgtKtnpKXbcn41AqbcUqX7cweAmu',
            property: 'token2_pool',
            liquidityToken: 'uUSD-USDt-LP',
            type: 'veStableAMM',
          },
        },
      },
      tzBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1PWx2mnDueood7fEmfbBDKx1D9BAnnXitn',
        mapId: 31,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          'WBTC.e': {
            contract: 'KT1Ws4qpXFVRKctB5mJcahFVcpcUi2WbxWM3',
            property: 'token2_pool',
            liquidityToken: 'tzbtc-wbtc.e-LP',
            type: 'veStableAMM',
          },
        },
      },
      kUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1K9gCRgaLRFKTErYt1wVxA3Frb9FjasjTV',
        mapId: 380,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1HgFcDE8ZXNdT1aXXKpMbZc6GkUS2VHiPo',
            property: 'token2_pool',
            liquidityToken: 'usdc.e-kusd-LP',
            type: 'veStableAMM',
          },
          USDt: {
            contract: 'KT1XfZi2K6gVM3wXDyeCwMhaT88JueVEAB8r',
            property: 'token2_pool',
            liquidityToken: 'kUSD-USDt-LP',
            type: 'veStableAMM',
          },
        },
      },
      'WBTC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          tzBTC: {
            contract: 'KT1Ws4qpXFVRKctB5mJcahFVcpcUi2WbxWM3',
            property: 'token1_pool',
            liquidityToken: 'tzbtc-wbtc.e-LP',
            type: 'veStableAMM',
          },
        },
      },
      'USDC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 2,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          kUSD: {
            contract: 'KT1HgFcDE8ZXNdT1aXXKpMbZc6GkUS2VHiPo',
            property: 'token1_pool',
            liquidityToken: 'usdc.e-kusd-LP',
            type: 'veStableAMM',
          },
          uUSD: {
            contract: 'KT1Ji4hVDeQ5Ru7GW1Tna9buYSs3AppHLwj9',
            property: 'token1_pool',
            liquidityToken: 'usdc.e-uusd-LP',
            type: 'veStableAMM',
          },
          'DAI.e': {
            contract: 'KT1Hxn43imdKTWXEZsLVdoUsS1R4hWTSXgDs',
            property: 'token2_pool',
            liquidityToken: 'dai.e-usdc.e-LP',
            type: 'veStableAMM',
          },
          USDtz: {
            contract: 'KT1995R6hTYzXjV52mGtf6MboPRhzbJBJe6q',
            property: 'token1_pool',
            liquidityToken: 'usdtz-usdc.e-LP',
            type: 'veStableAMM',
          },
          'BUSD.e': {
            contract: 'KT1LutmpUK8TDkA19tLCQNr7Eh7kbEsP3uiP',
            property: 'token1_pool',
            liquidityToken: 'busd.e-usdc.e-LP',
            type: 'veStableAMM',
          },
          'USDT.e': {
            contract: 'KT1PU4Ce89RyF1itwYxknVNcvtUWKdKy6rvQ',
            property: 'token1_pool',
            liquidityToken: 'usdt.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'DAI.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 6,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1Hxn43imdKTWXEZsLVdoUsS1R4hWTSXgDs',
            property: 'token1_pool',
            liquidityToken: 'dai.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      USDtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
        mapId: 36,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1995R6hTYzXjV52mGtf6MboPRhzbJBJe6q',
            property: 'token2_pool',
            liquidityToken: 'usdtz-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'USDT.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 3,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1PU4Ce89RyF1itwYxknVNcvtUWKdKy6rvQ',
            property: 'token2_pool',
            liquidityToken: 'usdt.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'BUSD.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 7,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1LutmpUK8TDkA19tLCQNr7Eh7kbEsP3uiP',
            property: 'token2_pool',
            liquidityToken: 'busd.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      ETHtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
        mapId: 199,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'WETH.e': {
            contract: 'KT1BKcLyaaNf2SHfAivBFBqdFoYTjuc78Aff',
            property: 'token2_pool',
            liquidityToken: 'ethtz-weth.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'WETH.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ETHtz: {
            contract: 'KT1BKcLyaaNf2SHfAivBFBqdFoYTjuc78Aff',
            property: 'token1_pool',
            liquidityToken: 'ethtz-weth.e-LP',
            type: 'veStableAMM',
          },
          'WETH.p': {
            contract: 'KT1QoXQBq7L1fYZXXgRogPbh112sfL1Rp4h2',
            property: 'token1_pool',
            liquidityToken: 'WMATIC.p-WETH.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'agEUR.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 8,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          EURL: {
            contract: 'KT1Nrbzh6WqwDgJ513qTEQqnmJTPpRv6ZKr5',
            property: 'token1_pool',
            liquidityToken: 'eurl-agEUR.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      EURL: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JBNFcB5tiycHNdYGYCtR3kk6JaJysUCi8',
        mapId: 44140,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          'agEUR.e': {
            contract: 'KT1Nrbzh6WqwDgJ513qTEQqnmJTPpRv6ZKr5',
            property: 'token2_pool',
            liquidityToken: 'eurl-agEUR.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      USDt: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o',
        mapId: 198031,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          kUSD: {
            contract: 'KT1XfZi2K6gVM3wXDyeCwMhaT88JueVEAB8r',
            property: 'token1_pool',
            liquidityToken: 'kUSD-USDt-LP',
            type: 'veStableAMM',
          },
          uUSD: {
            contract: 'KT19XPttmgtKtnpKXbcn41AqbcUqX7cweAmu',
            property: 'token1_pool',
            liquidityToken: 'uUSD-USDt-LP',
            type: 'veStableAMM',
          },
        },
      },

      'MATIC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 4,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'WMATIC.p': {
            contract: 'KT1WvoCeiWbSMHJLBGXw4q5W1i1U876GqjAe',
            property: 'token1_pool',
            liquidityToken: 'WMATIC.p-MATIC.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'WMATIC.p': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CNyTPmBJ5hcqDPbPkFtoe76LifXyHUvqc',
        mapId: 331060,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'MATIC.e': {
            contract: 'KT1WvoCeiWbSMHJLBGXw4q5W1i1U876GqjAe',
            property: 'token2_pool',
            liquidityToken: 'WMATIC.p-MATIC.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'WETH.p': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CNyTPmBJ5hcqDPbPkFtoe76LifXyHUvqc',
        mapId: 331060,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'WETH.e': {
            contract: 'KT1QoXQBq7L1fYZXXgRogPbh112sfL1Rp4h2',
            property: 'token2_pool',
            liquidityToken: 'WMATIC.p-WETH.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'WMATIC.p-WETH.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JEwoaaCHwof9ERqqZzpSuKXwwgCwV6bhV',
        mapId: 333925,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'WMATIC.p-MATIC.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VBQbNYvD5VGRRMfgovNehtoevXeTMyQWm',
        mapId: 331074,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'kUSD-USDt-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JaYzZooZmq9JP9FScjLShbUco8Pn8Ckct',
        mapId: 231140,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'uUSD-USDt-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Doix5iZZsQzKY3ANDkoca6GfCtJbXY9sk',
        mapId: 231143,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'eurl-agEUR.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1X4fkZ1f3uCpBqC1HZemsSNFbqm6bkpgfb',
        mapId: 210417,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdt.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CDeAxaiqbA5aMkPMmqqYXxqgfFwocJHza',
        mapId: 198730,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdtz-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1P9GyfYrW9EZ8m3jej2cMu49fpmEbYa2h5',
        mapId: 193185,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },
      'busd.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1G76qEBDqmAXLCCVg4ZuNbrENsxFe8aECe',
        mapId: 193188,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'dai.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TupjqgajsoArMKK3ifmNveKhU2XpTJrH8',
        mapId: 187493,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'ethtz-weth.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT19ga4Vz26GRBdn8oibR4WFF7aDF4oE9Ch3',
        mapId: 187480,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'tzbtc-wbtc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HJbndQjBEhAqR3Vj3NaP3RwFGudJf5YP4',
        mapId: 179610,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdc.e-kusd-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1J5HNUXnQwonSam3kmDQ5WbhynPPpVtgdN',
        mapId: 181898,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdc.e-uusd-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Ah4Fe2hjcRdVCiCQDhRDZ2vDYLdYNjY51',
        mapId: 181890,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },
      'ctez-XTZ-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DMnJvNrFYc8N9Ptxhw3NtqKN7AWqxCpkS',
        mapId: 171168,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {},
      },
    },
  },
  AMM: {
    testnet: {
      ctez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HZW9FWJt6aU8x4nr6UiBry2eUCA7xEFb1',
        mapId: 26976,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          tez: {
            contract: 'KT1AD4khq2R9q6DDbRY5CvUk8MXoshqsatGS',
            property: 'ctezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
          'DAI.e': {
            contract: 'KT1Gt8rQtPn6DSNnnZ1JGcf1CVdHA6CZhRAJ',
            property: 'token2_pool',
            liquidityToken: 'CTEZ-DAI.E-LP',
            type: 'oldAMM',
          },
          'BUSD.e': {
            contract: 'KT1UavMEguhWjJ8WdAujpco3tyXyXkKUYyby',
            property: 'token2_pool',
            liquidityToken: 'CTEZ-BUSD.E-LP',
            type: 'oldAMM',
          },
          PLENTY: {
            contract: 'KT1DWBEpG82w6VEVTDV2Z9NEWUe5Xxu9CH1G',
            property: 'token1_pool',
            liquidityToken: 'ctezPlentyLP',
            type: 'oldAMM',
          },
        },
      },
      'DAI.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 7,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1Gt8rQtPn6DSNnnZ1JGcf1CVdHA6CZhRAJ',
            property: 'token1_pool',
            liquidityToken: 'CTEZ-DAI.E-LP',
            type: 'oldAMM',
          },
        },
      },
      tez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XL89VUosEQh5Dk9fBBwrepXBw7V4EdqL5',
        READ_TYPE: 'XTZ',
        CALL_TYPE: 'XTZ',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1AD4khq2R9q6DDbRY5CvUk8MXoshqsatGS',
            property: 'tezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },
      'BUSD.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1UavMEguhWjJ8WdAujpco3tyXyXkKUYyby',
            property: 'token1_pool',
            liquidityToken: 'CTEZ-BUSD.E-LP',
            type: 'oldAMM',
          },
        },
      },
      PLENTY: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Mdk7TfHjbwb2ciTTsySj9gV9W9uc7HxCu',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          wWETH: {
            contract: 'KT1RG7ouxNLs5Mtdz9xKQyWDtxcC3LPuXcZF',
            property: 'token2_pool',
            liquidityToken: 'PlentyWETHLP',
            type: 'oldAMM',
          },
          wUSDC: {
            contract: 'KT1NBrkfVDM42B9q7hJcNwx1efcntwDF8fzf',
            property: 'token2_pool',
            liquidityToken: 'PlentyUSDCLP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1DWBEpG82w6VEVTDV2Z9NEWUe5Xxu9CH1G',
            property: 'token2_pool',
            liquidityToken: 'ctezPlentyLP',
            type: 'oldAMM',
          },
          wDAI: {
            contract: 'KT1Qc52zbRaKi5cwCBnx8o4FSDbdxuePLqYB',
            property: 'token2_pool',
            liquidityToken: 'PltDaiLp',
            type: 'oldAMM',
          },
          WRAP: {
            contract: 'KT1HePFs4aA5u6A5BDw3ot7LuXvqa4ebqrTQ',
            property: 'token2_pool',
            liquidityToken: 'WrapLP',
            type: 'oldAMM',
          },
        },
      },
      WRAP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1L1xYJit22TmuhDXaeng4AZDhRqZwcacNj',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          wDAI: {
            contract: 'KT1PnFKMA2v1ngvYUh5UKtaDuM5WxskrunFm',
            property: 'token1_pool',
            liquidityToken: 'WrapDaiLp',
            type: 'oldAMM',
          },
          PLENTY: {
            contract: 'KT1HePFs4aA5u6A5BDw3ot7LuXvqa4ebqrTQ',
            property: 'token1_pool',
            liquidityToken: 'WrapLP',
            type: 'oldAMM',
          },
        },
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DJwRryZ11dGDnqmmRtTiSsgxQDY4bw3j4',
        READ_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Qc52zbRaKi5cwCBnx8o4FSDbdxuePLqYB',
            property: 'token1_pool',
            liquidityToken: 'PltDaiLp',
            type: 'oldAMM',
          },
          WRAP: {
            contract: 'KT1PnFKMA2v1ngvYUh5UKtaDuM5WxskrunFm',
            property: 'token2_pool',
            liquidityToken: 'WrapDaiLp',
            type: 'oldAMM',
          },
        },
      },
      wUSDC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Bjfjuwfpgm5R3iXq8PBM9zqd3jEK2jiy5',
        mapId: 34178,
        READ_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1NBrkfVDM42B9q7hJcNwx1efcntwDF8fzf',
            property: 'token1_pool',
            liquidityToken: 'PlentyUSDCLP',
            type: 'oldAMM',
          },
        },
      },
      wWETH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Bjfjuwfpgm5R3iXq8PBM9zqd3jEK2jiy5',
        mapId: 34178,
        READ_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1RG7ouxNLs5Mtdz9xKQyWDtxcC3LPuXcZF',
            property: 'token1_pool',
            liquidityToken: 'PlentyWETHLP',
            type: 'oldAMM',
          },
        },
      },
      WrapLP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LHy8buFGtsZJjMc4C89Wk8VTowqaUwPbY',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      PltDaiLp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CAmNNoetuxeYZWJLSmbh9N66SDYT8tkBJ',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      WrapDaiLp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LcFzpTtkDtZwHty12PSuEsscxMPtdA2ZR',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'CTEZ-DAI.E-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Q4Z7DWPE7piJJ5ZkWmCqtP7o3EgfBr4s5',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'CTEZ-BUSD.E-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Ta8cCFkxGLx8UFzQQLqFSbzk5RAu8aTZj',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      ctezPlentyLP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1ESYSFLGWtmxZFoQG6vMkMt6Cqo73vjS57',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-XTZ-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Rp1fLJPFiR3w5iYSB1zxz4aDWL3Biiqhy',
        mapId: 80419,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {},
      },
      PlentyWETHLP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XReQKShV1t62JKyFAKUqHcnAdLFMpnTu7',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      PlentyUSDCLP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1H2w5EZKJeGSeMLrRBuASfN1YoaUmotnSB',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
    },
    mainnet: {
      tez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1BG1oEqQckYBRBCyaAcq1iQXkp8PVXhSVr',
        READ_TYPE: 'XTZ',
        CALL_TYPE: 'XTZ',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1CAYNQGvYSF5UvHK21grMrKpe2563w9UcX',
            property: 'tezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
        },
      },
      PLENTY: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        mapId: 3943,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          wUSDC: {
            contract: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            property: 'token2_pool',
            liquidityToken: 'PlentywUSDClp',
            type: 'oldAMM',
          },
          wBUSD: {
            contract: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            property: 'token2_pool',
            liquidityToken: 'PlentywBUSDlp',
            type: 'oldAMM',
          },
          wWBTC: {
            contract: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            property: 'token2_pool',
            liquidityToken: 'PlentywWBTClp',
            type: 'oldAMM',
          },
          wMATIC: {
            contract: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            property: 'token2_pool',
            liquidityToken: 'PlentywMaticlp',
            type: 'oldAMM',
          },
          wLINK: {
            contract: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            property: 'token2_pool',
            liquidityToken: 'Plentywlinklp',
            type: 'oldAMM',
          },
          USDtz: {
            contract: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            property: 'token2_pool',
            liquidityToken: 'Plentyusdtzlp',
            type: 'oldAMM',
          },
          hDAO: {
            contract: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            property: 'token2_pool',
            liquidityToken: 'Plenty-hDAO-LP',
            type: 'oldAMM',
          },
          ETHtz: {
            contract: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            property: 'token2_pool',
            liquidityToken: 'Plenty-ETHtz-LP',
            type: 'oldAMM',
          },
          wWETH: {
            contract: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            property: 'token2_pool',
            liquidityToken: 'Plenty-wWETH-LP',
            type: 'oldAMM',
          },
          kUSD: {
            contract: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            property: 'token2_pool',
            liquidityToken: 'PLENTY-kUSD-LP',
            type: 'oldAMM',
          },
          QUIPU: {
            contract: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            property: 'token2_pool',
            liquidityToken: 'Plenty-QUIPU-LP',
            type: 'oldAMM',
          },
          WRAP: {
            contract: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            property: 'token2_pool',
            liquidityToken: 'Plentywraplp',
            type: 'oldAMM',
          },
          UNO: {
            contract: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            property: 'token2_pool',
            liquidityToken: 'Plenty-UNO-LP',
            type: 'oldAMM',
          },
          KALAM: {
            contract: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            property: 'token2_pool',
            liquidityToken: 'Plentykalamlp',
            type: 'oldAMM',
          },
          SMAK: {
            contract: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            property: 'token2_pool',
            liquidityToken: 'Plenty-SMAK-LP',
            type: 'oldAMM',
          },
          tzBTC: {
            contract: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            property: 'token2_pool',
            liquidityToken: 'PlentytzBTClp',
            type: 'oldAMM',
          },
          uUSD: {
            contract: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            property: 'token2_pool',
            liquidityToken: 'Plenty-uUSD-LP',
            type: 'oldAMM',
          },
          GIF: {
            contract: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            property: 'token2_pool',
            liquidityToken: 'PlentyGiflp',
            type: 'oldAMM',
          },
          YOU: {
            contract: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            property: 'token2_pool',
            liquidityToken: 'Plenty-YOU-LP',
            type: 'oldAMM',
          },
          wUSDT: {
            contract: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            property: 'token2_pool',
            liquidityToken: 'PlentywUSDTlp',
            type: 'oldAMM',
          },
          wDAI: {
            contract: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            property: 'token2_pool',
            liquidityToken: 'PlentywDAIlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            property: 'token2_pool',
            liquidityToken: 'PlentyCtezlp',
            type: 'oldAMM',
          },
        },
      },
      wUSDC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 17,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            property: 'token1_pool',
            liquidityToken: 'PlentywUSDClp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1PZpbmKtGE6ZyYeF8entfjuGGT7CRUCF5g',
            property: 'token1_pool',
            liquidityToken: 'ctez-wusdc-lp',
            type: 'oldAMM',
          },
          uUSD: {
            contract: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            property: 'token1_pool',
            liquidityToken: 'uusd-wusdc-LP',
            type: 'oldAMM',
          },
        },
      },
      wBUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            property: 'token1_pool',
            liquidityToken: 'PlentywBUSDlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1GN7PHpFsH43ypFyE2hxNMdxqTuiCGm4Pm',
            property: 'token1_pool',
            liquidityToken: 'ctez-wbusd-lp',
            type: 'oldAMM',
          },
        },
      },
      wWBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 19,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            property: 'token1_pool',
            liquidityToken: 'PlentywWBTClp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1XPUvZHc1YKTJYnGbB755V95hDgvS1qQYf',
            property: 'token1_pool',
            liquidityToken: 'ctez-wwbtc-lp',
            type: 'oldAMM',
          },
        },
      },
      wUSDT: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 18,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            property: 'token1_pool',
            liquidityToken: 'PlentywUSDTlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1AfTwam4bNPawLv4bWqSj9GsVDPsf5F5bQ',
            property: 'token1_pool',
            liquidityToken: 'ctez-wusdt-lp',
            type: 'oldAMM',
          },
        },
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            property: 'token1_pool',
            liquidityToken: 'PlentywDAIlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1L5qd9xPWjiEX6ZNovhaty228ASg6jCE5p',
            property: 'token1_pool',
            liquidityToken: 'ctez-wdai-lp',
            type: 'oldAMM',
          },
        },
      },
      wMATIC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 11,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            property: 'token1_pool',
            liquidityToken: 'PlentywMaticlp',
            type: 'oldAMM',
          },
        },
      },
      wLINK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 10,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            property: 'token1_pool',
            liquidityToken: 'Plentywlinklp',
            type: 'oldAMM',
          },
        },
      },
      wWETH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 20,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            property: 'token1_pool',
            liquidityToken: 'Plenty-wWETH-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1WwqM2MH38PSQbECxPngBVLSbqJUHzCFAH',
            property: 'token1_pool',
            liquidityToken: 'ctez-wweth-lp',
            type: 'oldAMM',
          },
        },
      },
      UNO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1ErKVqEhG9jxXgUG2KGLW3bNM7zXHX8SDF',
        mapId: 10978,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            property: 'token1_pool',
            liquidityToken: 'Plenty-UNO-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1XLFZ2RS8vCUmHwBG39mq7zELhnLKn9JUz',
            property: 'token1_pool',
            liquidityToken: 'ctez-uno-lp',
            type: 'oldAMM',
          },
        },
      },
      uUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
        mapId: 7706,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1Ji4hVDeQ5Ru7GW1Tna9buYSs3AppHLwj9',
            property: 'token2_pool',
            liquidityToken: 'usdc.e-uusd-LP',
            type: 'veStableAMM',
          },
          PLENTY: {
            contract: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            property: 'token1_pool',
            liquidityToken: 'Plenty-uUSD-LP',
            type: 'oldAMM',
          },
          wUSDC: {
            contract: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            property: 'token2_pool',
            liquidityToken: 'uusd-wusdc-LP',
            type: 'oldAMM',
          },
          YOU: {
            contract: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            property: 'token2_pool',
            liquidityToken: 'uusd-you-LP',
            type: 'oldAMM',
          },
          uDEFI: {
            contract: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            property: 'token2_pool',
            liquidityToken: 'uusd-udefi-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1Rx3pQzsn4FBuuYhcWsqUS7vWFx3ktqSWD',
            property: 'token1_pool',
            liquidityToken: 'ctez-uusd-lp',
            type: 'oldAMM',
          },
          USDt: {
            contract: 'KT19XPttmgtKtnpKXbcn41AqbcUqX7cweAmu',
            property: 'token2_pool',
            liquidityToken: 'uUSD-USDt-LP',
            type: 'veStableAMM',
          },
        },
      },
      uDEFI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
        mapId: 7706,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          uUSD: {
            contract: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            property: 'token1_pool',
            liquidityToken: 'uusd-udefi-LP',
            type: 'oldAMM',
          },
        },
      },
      YOU: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Xobej4mc6XgEjDoJoHtTKgbD1ELMvcQuL',
        mapId: 7715,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            property: 'token1_pool',
            liquidityToken: 'Plenty-YOU-LP',
            type: 'oldAMM',
          },
          uUSD: {
            contract: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            property: 'token1_pool',
            liquidityToken: 'uusd-you-LP',
            type: 'oldAMM',
          },
        },
      },
      USDtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
        mapId: 36,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            property: 'token1_pool',
            liquidityToken: 'Plentyusdtzlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1PWAXfPatPWBNJUxTHin4ECin1kYJHHnsr',
            property: 'token1_pool',
            liquidityToken: 'ctez-usdtz-lp',
            type: 'oldAMM',
          },
          'USDC.e': {
            contract: 'KT1995R6hTYzXjV52mGtf6MboPRhzbJBJe6q',
            property: 'token2_pool',
            liquidityToken: 'usdtz-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },
      WRAP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
        mapId: 1777,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            property: 'token1_pool',
            liquidityToken: 'Plentywraplp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT19Qe4KbEVAiaVeNsgo9Tkqa6qvZho8c4W5',
            property: 'token1_pool',
            liquidityToken: 'ctez-wrap-lp',
            type: 'oldAMM',
          },
        },
      },
      GIF: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
        mapId: 7654,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            property: 'token1_pool',
            liquidityToken: 'PlentyGiflp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1VnFPwDGJRt5ufMgcWafM2WWcZCC77gpT3',
            property: 'token1_pool',
            liquidityToken: 'ctez-gif-lp',
            type: 'oldAMM',
          },
        },
      },
      tzBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1PWx2mnDueood7fEmfbBDKx1D9BAnnXitn',
        mapId: 31,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          'WBTC.e': {
            contract: 'KT1Ws4qpXFVRKctB5mJcahFVcpcUi2WbxWM3',
            property: 'token2_pool',
            liquidityToken: 'tzbtc-wbtc.e-LP',
            type: 'veStableAMM',
          },
          PLENTY: {
            contract: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            property: 'token1_pool',
            liquidityToken: 'PlentytzBTClp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1Wryxte8NnVCm569yb8dcSPmvFBVs4ufD6',
            property: 'token1_pool',
            liquidityToken: 'ctez-tzbtc-lp',
            type: 'oldAMM',
          },
        },
      },
      ctez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SjXiUX63QvdNMcM2m492f7kuf8JxXRLp4',
        mapId: 20920,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          USDt: {
            contract: 'KT1D1NcffeDR3xQ75fUFoJXZzD6WQp96Je3L',
            property: 'token1_pool',
            liquidityToken: 'usdt-ctez-LP',
            type: 'veAMM',
          },
          'USDC.e': {
            contract: 'KT1Dhy1gVW3PSC9cms9QJ7xPMPPpip2V9aA6',
            property: 'token1_pool',
            liquidityToken: 'usdc.e-ctez-LP',
            type: 'veAMM',
          },
          'WBTC.e': {
            contract: 'KT1KapPE9E65fBozpJqsuCgaLPytvKQduPvF',
            property: 'token1_pool',
            liquidityToken: 'wbtc.e-ctez-LP',
            type: 'veAMM',
          },
          'WETH.e': {
            contract: 'KT1Qs52cCz1gLK8LYi6cZJm7YjExg6MYLdkG',
            property: 'token1_pool',
            liquidityToken: 'weth.e-ctez-LP',
            type: 'veAMM',
          },
          'MATIC.e': {
            contract: 'KT1DiaHKX1jveXp1HGLE8X3MdxA13SLDRT7S',
            property: 'token1_pool',
            liquidityToken: 'matic.e-ctez-LP',
            type: 'veAMM',
          },
          'WMATIC.p': {
            contract: 'KT1SmLuyw2gfKme6EkUnEQpamCGMr1mDQVeK',
            property: 'token1_pool',
            liquidityToken: 'wmatic.p-ctez-LP',
            type: 'veAMM',
          },
          'WETH.p': {
            contract: 'KT1Nta8VsKdm6J9XBYknKeeDciQFopKkkMAg',
            property: 'token1_pool',
            liquidityToken: 'ctez-WETH.e-LP',
            type: 'veAMM',
          },
          'LINK.e': {
            contract: 'KT1GGaeBoJNxD3ieHtRiJhmcMguVLroksBGn',
            property: 'token1_pool',
            liquidityToken: 'link.e-ctez-LP',
            type: 'veAMM',
          },
          tez: {
            contract: 'KT1CAYNQGvYSF5UvHK21grMrKpe2563w9UcX',
            property: 'ctezPool',
            liquidityToken: 'ctez-XTZ-LP',
            type: 'xtz',
          },
          DOGA: {
            contract: 'KT1NLxs6rSYaJikjuCRVbVfg3p9ehfVuQgHC',
            property: 'token1_pool',
            liquidityToken: 'ctez-doga-lp',
            type: 'veAMM',
          },
          PLENTY: {
            contract: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            property: 'token1_pool',
            liquidityToken: 'PlentyCtezlp',
            type: 'oldAMM',
          },
          kUSD: {
            contract: 'KT1X1nkqJDR1UHwbfpcnME5Z7agJLjUQNguB',
            property: 'token2_pool',
            liquidityToken: 'ctez-kusd-lp',
            type: 'oldAMM',
          },
          WRAP: {
            contract: 'KT19Qe4KbEVAiaVeNsgo9Tkqa6qvZho8c4W5',
            property: 'token2_pool',
            liquidityToken: 'ctez-wrap-lp',
            type: 'oldAMM',
          },
          USDtz: {
            contract: 'KT1PWAXfPatPWBNJUxTHin4ECin1kYJHHnsr',
            property: 'token2_pool',
            liquidityToken: 'ctez-usdtz-lp',
            type: 'oldAMM',
          },
          wWBTC: {
            contract: 'KT1XPUvZHc1YKTJYnGbB755V95hDgvS1qQYf',
            property: 'token2_pool',
            liquidityToken: 'ctez-wwbtc-lp',
            type: 'oldAMM',
          },
          wUSDC: {
            contract: 'KT1PZpbmKtGE6ZyYeF8entfjuGGT7CRUCF5g',
            property: 'token2_pool',
            liquidityToken: 'ctez-wusdc-lp',
            type: 'oldAMM',
          },
          wUSDT: {
            contract: 'KT1AfTwam4bNPawLv4bWqSj9GsVDPsf5F5bQ',
            property: 'token2_pool',
            liquidityToken: 'ctez-wusdt-lp',
            type: 'oldAMM',
          },
          wBUSD: {
            contract: 'KT1GN7PHpFsH43ypFyE2hxNMdxqTuiCGm4Pm',
            property: 'token2_pool',
            liquidityToken: 'ctez-wbusd-lp',
            type: 'oldAMM',
          },
          wDAI: {
            contract: 'KT1L5qd9xPWjiEX6ZNovhaty228ASg6jCE5p',
            property: 'token2_pool',
            liquidityToken: 'ctez-wdai-lp',
            type: 'oldAMM',
          },
          KALAM: {
            contract: 'KT1HuZVo3ZKRhkaHDsYXZ2fvmVfkY1FrbEDj',
            property: 'token2_pool',
            liquidityToken: 'ctez-kalam-lp',
            type: 'oldAMM',
          },
          GIF: {
            contract: 'KT1VnFPwDGJRt5ufMgcWafM2WWcZCC77gpT3',
            property: 'token2_pool',
            liquidityToken: 'ctez-gif-lp',
            type: 'oldAMM',
          },
          ETHtz: {
            contract: 'KT1GSYhwanehtwCK3NPfkMFbD1bNQmvosbqL',
            property: 'token2_pool',
            liquidityToken: 'ctez-ethtz-lp',
            type: 'oldAMM',
          },
          QUIPU: {
            contract: 'KT1Ss8rb1UFVqG2LYEU5g4NEbK5SqW5Xadwp',
            property: 'token2_pool',
            liquidityToken: 'ctez-quipu-lp',
            type: 'oldAMM',
          },
          hDAO: {
            contract: 'KT1RSxExbbRwQdYBpRHWup9vxvw1Hp25vFVM',
            property: 'token2_pool',
            liquidityToken: 'ctez-hdao-lp',
            type: 'oldAMM',
          },
          tzBTC: {
            contract: 'KT1Wryxte8NnVCm569yb8dcSPmvFBVs4ufD6',
            property: 'token2_pool',
            liquidityToken: 'ctez-tzbtc-lp',
            type: 'oldAMM',
          },
          wWETH: {
            contract: 'KT1WwqM2MH38PSQbECxPngBVLSbqJUHzCFAH',
            property: 'token2_pool',
            liquidityToken: 'ctez-wweth-lp',
            type: 'oldAMM',
          },
          uUSD: {
            contract: 'KT1Rx3pQzsn4FBuuYhcWsqUS7vWFx3ktqSWD',
            property: 'token2_pool',
            liquidityToken: 'ctez-uusd-lp',
            type: 'oldAMM',
          },
          SMAK: {
            contract: 'KT1Qg4FmXDmViQgyYLT5QkgZQSmPKvKjZbzn',
            property: 'token2_pool',
            liquidityToken: 'ctez-smak-lp',
            type: 'oldAMM',
          },
          UNO: {
            contract: 'KT1XLFZ2RS8vCUmHwBG39mq7zELhnLKn9JUz',
            property: 'token2_pool',
            liquidityToken: 'ctez-uno-lp',
            type: 'oldAMM',
          },
          CRUNCH: {
            contract: 'KT1WZztBneC9NrhVqvo9yXq8St7p8c1JVTxm',
            property: 'token2_pool',
            liquidityToken: 'ctez-crunch-lp',
            type: 'oldAMM',
          },
          PXL: {
            contract: 'KT1WR19YA8Go6GbTvtrMpUEEGSuQYxgz2ZVW',
            property: 'token2_pool',
            liquidityToken: 'ctez-pxl-lp',
            type: 'oldAMM',
          },
          INSTA: {
            contract: 'KT1EnESbHxmzd2XeENyYNege4L5TKxeatJk9',
            property: 'token2_pool',
            liquidityToken: 'ctez-insta-lp',
            type: 'oldAMM',
          },
          crDAO: {
            contract: 'KT1JkHBYp3Pq4TRSkFdc2CRoXFgDkJrfCqUe',
            property: 'token2_pool',
            liquidityToken: 'ctez-crdao-lp',
            type: 'oldAMM',
          },
          FLAME: {
            contract: 'KT1Bp3JAeeMP4GvJaspFw6zah6WydqqqHPNw',
            property: 'token2_pool',
            liquidityToken: 'ctez-flame-lp',
            type: 'oldAMM',
          },
          kDAO: {
            contract: 'KT1SP9WsMeLFTiHBXqEumTASYbF3CXh22aVV',
            property: 'token2_pool',
            liquidityToken: 'ctez-kdao-lp',
            type: 'oldAMM',
          },
          PAUL: {
            contract: 'KT1LixgLzdK4nseeD6MmmVpokuw9CvpVX9KW',
            property: 'token2_pool',
            liquidityToken: 'ctez-paul-lp',
            type: 'oldAMM',
          },
          EURL: {
            contract: 'KT1LqEgLikLE2obnyzgPJA6vMtnnG5agXVCn',
            property: 'token1_pool',
            liquidityToken: 'eurl-ctez-LP',
            type: 'veAMM',
          },
        },
      },
      DOGA: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Ha4yFVeyzw6KRAdkzq6TxDHB97KG4pZe8',
        mapId: 134335,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 5,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1NLxs6rSYaJikjuCRVbVfg3p9ehfVuQgHC',
            property: 'token2_pool',
            liquidityToken: 'ctez-doga-lp',
            type: 'veAMM',
          },
        },
      },
      PAUL: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19DUSZw7mfeEATrbWVPHRrWNVbNnmfFAE6',
        mapId: 6901,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1LixgLzdK4nseeD6MmmVpokuw9CvpVX9KW',
            property: 'token1_pool',
            liquidityToken: 'ctez-paul-lp',
            type: 'oldAMM',
          },
        },
      },
      KALAM: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
        mapId: 4178,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 10,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            property: 'token1_pool',
            liquidityToken: 'Plentykalamlp',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1HuZVo3ZKRhkaHDsYXZ2fvmVfkY1FrbEDj',
            property: 'token1_pool',
            liquidityToken: 'ctez-kalam-lp',
            type: 'oldAMM',
          },
        },
      },
      kUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1K9gCRgaLRFKTErYt1wVxA3Frb9FjasjTV',
        mapId: 380,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1HgFcDE8ZXNdT1aXXKpMbZc6GkUS2VHiPo',
            property: 'token2_pool',
            liquidityToken: 'usdc.e-kusd-LP',
            type: 'veStableAMM',
          },
          PLENTY: {
            contract: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            property: 'token1_pool',
            liquidityToken: 'PLENTY-kUSD-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1X1nkqJDR1UHwbfpcnME5Z7agJLjUQNguB',
            property: 'token1_pool',
            liquidityToken: 'ctez-kusd-lp',
            type: 'oldAMM',
          },
          USDt: {
            contract: 'KT1XfZi2K6gVM3wXDyeCwMhaT88JueVEAB8r',
            property: 'token2_pool',
            liquidityToken: 'kUSD-USDt-LP',
            type: 'veStableAMM',
          },
        },
      },
      hDAO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
        mapId: 515,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            property: 'token1_pool',
            liquidityToken: 'Plenty-hDAO-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1RSxExbbRwQdYBpRHWup9vxvw1Hp25vFVM',
            property: 'token1_pool',
            liquidityToken: 'ctez-hdao-lp',
            type: 'oldAMM',
          },
        },
      },
      ETHtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
        mapId: 199,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            property: 'token1_pool',
            liquidityToken: 'Plenty-ETHtz-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1GSYhwanehtwCK3NPfkMFbD1bNQmvosbqL',
            property: 'token1_pool',
            liquidityToken: 'ctez-ethtz-lp',
            type: 'oldAMM',
          },
          'WETH.e': {
            contract: 'KT1BKcLyaaNf2SHfAivBFBqdFoYTjuc78Aff',
            property: 'token2_pool',
            liquidityToken: 'ethtz-weth.e-LP',
            type: 'veStableAMM',
          },
          // wWETH: {
          //   contract: 'KT196bPdVJqY1DF3y7aQ9Cn5tcGQeMrrjWTp',
          //   property: 'token1_pool',
          //   liquidityToken: 'wWETH-ETHtz-LP',
          // },
        },
      },
      SMAK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TwzD6zV3WeJ39ukuqxcfK2fJCnhvrdN1X',
        mapId: 1798,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 3,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            property: 'token1_pool',
            liquidityToken: 'Plenty-SMAK-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1Qg4FmXDmViQgyYLT5QkgZQSmPKvKjZbzn',
            property: 'token1_pool',
            liquidityToken: 'ctez-smak-lp',
            type: 'oldAMM',
          },
        },
      },
      QUIPU: {
        ICON: '',
        TOKEN_CONTRACT: 'KT193D4vozYnhGJQVtw7CoxxqphqUEEwK6Vb',
        mapId: 12043,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            property: 'token1_pool',
            liquidityToken: 'Plenty-QUIPU-LP',
            type: 'oldAMM',
          },
          ctez: {
            contract: 'KT1Ss8rb1UFVqG2LYEU5g4NEbK5SqW5Xadwp',
            property: 'token1_pool',
            liquidityToken: 'ctez-quipu-lp',
            type: 'oldAMM',
          },
        },
      },
      CRUNCH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1BHCumksALJQJ8q8to2EPigPW6qpyTr7Ng',
        mapId: 2809,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1WZztBneC9NrhVqvo9yXq8St7p8c1JVTxm',
            property: 'token1_pool',
            liquidityToken: 'ctez-crunch-lp',
            type: 'oldAMM',
          },
        },
      },
      FLAME: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Wa8yqRBpFCusJWgcQyjhRz7hUQAmFxW7j',
        mapId: 2545,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1Bp3JAeeMP4GvJaspFw6zah6WydqqqHPNw',
            property: 'token1_pool',
            liquidityToken: 'ctez-flame-lp',
            type: 'oldAMM',
          },
        },
      },
      kDAO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JkoE42rrMBP9b2oDhbx6EUr26GcySZMUH',
        mapId: 7250,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1SP9WsMeLFTiHBXqEumTASYbF3CXh22aVV',
            property: 'token1_pool',
            liquidityToken: 'ctez-kdao-lp',
            type: 'oldAMM',
          },
        },
      },
      INSTA: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19y6R8x53uDKiM46ahgguS6Tjqhdj2rSzZ',
        mapId: 13802,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1EnESbHxmzd2XeENyYNege4L5TKxeatJk9',
            property: 'token1_pool',
            liquidityToken: 'ctez-insta-lp',
            type: 'oldAMM',
          },
        },
      },
      crDAO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XPFjZqCULSnqfKaaYy8hJjeY63UNSGwXg',
        mapId: 4666,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1JkHBYp3Pq4TRSkFdc2CRoXFgDkJrfCqUe',
            property: 'token1_pool',
            liquidityToken: 'ctez-crdao-lp',
            type: 'oldAMM',
          },
        },
      },
      PXL: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK',
        mapId: 21182,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1WR19YA8Go6GbTvtrMpUEEGSuQYxgz2ZVW',
            property: 'token1_pool',
            liquidityToken: 'ctez-pxl-lp',
            type: 'oldAMM',
          },
        },
      },
      'WBTC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1KapPE9E65fBozpJqsuCgaLPytvKQduPvF',
            property: 'token2_pool',
            liquidityToken: 'wbtc.e-ctez-LP',
            type: 'veAMM',
          },
          tzBTC: {
            contract: 'KT1Ws4qpXFVRKctB5mJcahFVcpcUi2WbxWM3',
            property: 'token1_pool',
            liquidityToken: 'tzbtc-wbtc.e-LP',
            type: 'veStableAMM',
          },
        },
      },
      'USDC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 2,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1Dhy1gVW3PSC9cms9QJ7xPMPPpip2V9aA6',
            property: 'token2_pool',
            liquidityToken: 'usdc.e-ctez-LP',
            type: 'veAMM',
          },
          kUSD: {
            contract: 'KT1HgFcDE8ZXNdT1aXXKpMbZc6GkUS2VHiPo',
            property: 'token1_pool',
            liquidityToken: 'usdc.e-kusd-LP',
            type: 'veStableAMM',
          },
          uUSD: {
            contract: 'KT1Ji4hVDeQ5Ru7GW1Tna9buYSs3AppHLwj9',
            property: 'token1_pool',
            liquidityToken: 'usdc.e-uusd-LP',
            type: 'veStableAMM',
          },
          'DAI.e': {
            contract: 'KT1Hxn43imdKTWXEZsLVdoUsS1R4hWTSXgDs',
            property: 'token2_pool',
            liquidityToken: 'dai.e-usdc.e-LP',
            type: 'veStableAMM',
          },
          USDtz: {
            contract: 'KT1995R6hTYzXjV52mGtf6MboPRhzbJBJe6q',
            property: 'token1_pool',
            liquidityToken: 'usdtz-usdc.e-LP',
            type: 'veStableAMM',
          },
          'BUSD.e': {
            contract: 'KT1LutmpUK8TDkA19tLCQNr7Eh7kbEsP3uiP',
            property: 'token1_pool',
            liquidityToken: 'busd.e-usdc.e-LP',
            type: 'veStableAMM',
          },
          'USDT.e': {
            contract: 'KT1PU4Ce89RyF1itwYxknVNcvtUWKdKy6rvQ',
            property: 'token1_pool',
            liquidityToken: 'usdt.e-usdc.e-LP',
            type: 'veStableAMM',
          },
          'agEUR.e': {
            contract: 'KT1KZ93C79SqZw5CLUwwbLPxA5CwVGyKD365',
            property: 'token1_pool',
            liquidityToken: 'agEUR.e-usdc.e-LP',
            type: 'veAMM',
          },
        },
      },
      'LINK.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1GGaeBoJNxD3ieHtRiJhmcMguVLroksBGn',
            property: 'token2_pool',
            liquidityToken: 'link.e-ctez-LP',
            type: 'veAMM',
          },
        },
      },

      'MATIC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 4,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ctez: {
            contract: 'KT1DiaHKX1jveXp1HGLE8X3MdxA13SLDRT7S',
            property: 'token2_pool',
            liquidityToken: 'matic.e-ctez-LP',
            type: 'veAMM',
          },
          'WMATIC.p': {
            contract: 'KT1WvoCeiWbSMHJLBGXw4q5W1i1U876GqjAe',
            property: 'token1_pool',
            liquidityToken: 'WMATIC.p-MATIC.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'DAI.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 6,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1Hxn43imdKTWXEZsLVdoUsS1R4hWTSXgDs',
            property: 'token1_pool',
            liquidityToken: 'dai.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'WETH.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          ETHtz: {
            contract: 'KT1BKcLyaaNf2SHfAivBFBqdFoYTjuc78Aff',
            property: 'token1_pool',
            liquidityToken: 'ethtz-weth.e-LP',
            type: 'veStableAMM',
          },
          'WETH.p': {
            contract: 'KT1QoXQBq7L1fYZXXgRogPbh112sfL1Rp4h2',
            property: 'token1_pool',
            liquidityToken: 'WMATIC.p-WETH.e-LP',
            type: 'veStableAMM',
          },
          ctez: {
            contract: 'KT1Qs52cCz1gLK8LYi6cZJm7YjExg6MYLdkG',
            property: 'token2_pool',
            liquidityToken: 'weth.e-ctez-LP',
            type: 'veAMM',
          },
        },
      },

      'USDT.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 3,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1PU4Ce89RyF1itwYxknVNcvtUWKdKy6rvQ',
            property: 'token2_pool',
            liquidityToken: 'usdt.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'BUSD.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 7,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'USDC.e': {
            contract: 'KT1LutmpUK8TDkA19tLCQNr7Eh7kbEsP3uiP',
            property: 'token2_pool',
            liquidityToken: 'busd.e-usdc.e-LP',
            type: 'veStableAMM',
          },
        },
      },

      'agEUR.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 8,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          EURL: {
            contract: 'KT1Nrbzh6WqwDgJ513qTEQqnmJTPpRv6ZKr5',
            property: 'token1_pool',
            liquidityToken: 'eurl-agEUR.e-LP',
            type: 'veStableAMM',
          },
          'USDC.e': {
            contract: 'KT1KZ93C79SqZw5CLUwwbLPxA5CwVGyKD365',
            property: 'token2_pool',
            liquidityToken: 'agEUR.e-usdc.e-LP',
            type: 'veAMM',
          },
        },
      },

      EURL: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JBNFcB5tiycHNdYGYCtR3kk6JaJysUCi8',
        mapId: 44140,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          'agEUR.e': {
            contract: 'KT1Nrbzh6WqwDgJ513qTEQqnmJTPpRv6ZKr5',
            property: 'token2_pool',
            liquidityToken: 'eurl-agEUR.e-LP',
            type: 'veStableAMM',
          },
          ctez: {
            contract: 'KT1LqEgLikLE2obnyzgPJA6vMtnnG5agXVCn',
            property: 'token2_pool',
            liquidityToken: 'eurl-ctez-LP',
            type: 'veAMM',
          },
        },
      },

      USDt: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o',
        mapId: 198031,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          kUSD: {
            contract: 'KT1XfZi2K6gVM3wXDyeCwMhaT88JueVEAB8r',
            property: 'token1_pool',
            liquidityToken: 'kUSD-USDt-LP',
            type: 'veStableAMM',
          },
          uUSD: {
            contract: 'KT19XPttmgtKtnpKXbcn41AqbcUqX7cweAmu',
            property: 'token1_pool',
            liquidityToken: 'uUSD-USDt-LP',
            type: 'veStableAMM',
          },
          ctez: {
            contract: 'KT1D1NcffeDR3xQ75fUFoJXZzD6WQp96Je3L',
            property: 'token2_pool',
            liquidityToken: 'usdt-ctez-LP',
            type: 'veAMM',
          },
        },
      },

      'WMATIC.p': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CNyTPmBJ5hcqDPbPkFtoe76LifXyHUvqc',
        mapId: 331060,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'MATIC.e': {
            contract: 'KT1WvoCeiWbSMHJLBGXw4q5W1i1U876GqjAe',
            property: 'token2_pool',
            liquidityToken: 'WMATIC.p-MATIC.e-LP',
            type: 'veStableAMM',
          },
          ctez: {
            contract: 'KT1SmLuyw2gfKme6EkUnEQpamCGMr1mDQVeK',
            property: 'token2_pool',
            liquidityToken: 'wmatic.p-ctez-LP',
            type: 'veAMM',
          },
        },
      },

      'WETH.p': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CNyTPmBJ5hcqDPbPkFtoe76LifXyHUvqc',
        mapId: 331060,
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          'WETH.e': {
            contract: 'KT1QoXQBq7L1fYZXXgRogPbh112sfL1Rp4h2',
            property: 'token2_pool',
            liquidityToken: 'WMATIC.p-WETH.e-LP',
            type: 'veStableAMM',
          },
          ctez: {
            contract: 'KT1Nta8VsKdm6J9XBYknKeeDciQFopKkkMAg',
            property: 'token2_pool',
            liquidityToken: 'ctez-WETH.e-LP',
            type: 'veAMM',
          },
        },
      },

      'ctez-WETH.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1B5MPrS2KTTJ3VufVsr1RyMxuY1k5nhSdY',
        mapId: 333922,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'WMATIC.p-WETH.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JEwoaaCHwof9ERqqZzpSuKXwwgCwV6bhV',
        mapId: 333925,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'wmatic.p-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1FCXZu12H6G5Ujw8jPdektkuW5kWHEn49D',
        mapId: 331071,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'WMATIC.p-MATIC.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VBQbNYvD5VGRRMfgovNehtoevXeTMyQWm',
        mapId: 331074,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'eurl-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HFMKcdrLBH5RjC4waPVNprn8aywDEgwog',
        mapId: 280368,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'agEUR.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CDK8VMGy94sH4Mb6YzMmJX7HTsFqYTFee',
        mapId: 280378,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdt-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1BaGrWEPYsEyQEftyegLrGEqY2psAJh5GX',
        mapId: 244529,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'kUSD-USDt-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1JaYzZooZmq9JP9FScjLShbUco8Pn8Ckct',
        mapId: 231140,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'uUSD-USDt-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Doix5iZZsQzKY3ANDkoca6GfCtJbXY9sk',
        mapId: 231143,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'eurl-agEUR.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1X4fkZ1f3uCpBqC1HZemsSNFbqm6bkpgfb',
        mapId: 210417,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdt.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CDeAxaiqbA5aMkPMmqqYXxqgfFwocJHza',
        mapId: 198730,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdtz-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1P9GyfYrW9EZ8m3jej2cMu49fpmEbYa2h5',
        mapId: 193185,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },
      'busd.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1G76qEBDqmAXLCCVg4ZuNbrENsxFe8aECe',
        mapId: 193188,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'dai.e-usdc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TupjqgajsoArMKK3ifmNveKhU2XpTJrH8',
        mapId: 187493,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'ethtz-weth.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT19ga4Vz26GRBdn8oibR4WFF7aDF4oE9Ch3',
        mapId: 187480,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'weth.e-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SDugcNjEbNQe55TdQppiRP1wVDbmxHuF1',
        mapId: 188174,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'matic.e-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT18rVWWn2LMo2Hpy6KNhkhGgks21g11dxVv',
        mapId: 188159,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'link.e-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1WcS1cdwcbT3yyuYA6uzsYdZxBax5cGjdR',
        mapId: 188165,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
        DEX_PAIRS: {},
      },

      'usdc.e-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XpLzv4tqqsf2aLneDZgEeNFhKkH8JBGXv',
        mapId: 177380,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'wbtc.e-ctez-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VRAYxpCo5xHYmBLYmMAWxXoVYCVgpeuak',
        mapId: 177398,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'tzbtc-wbtc.e-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HJbndQjBEhAqR3Vj3NaP3RwFGudJf5YP4',
        mapId: 179610,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        CALL_TYPE: 'FA1.2',
      },
      'usdc.e-kusd-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1J5HNUXnQwonSam3kmDQ5WbhynPPpVtgdN',
        mapId: 181898,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'usdc.e-uusd-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Ah4Fe2hjcRdVCiCQDhRDZ2vDYLdYNjY51',
        mapId: 181890,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
      },

      PlentywBUSDlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
        mapId: 10749,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      PlentywUSDClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
        mapId: 10755,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      PlentywWBTClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
        mapId: 11051,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      PlentywMaticlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
        mapId: 11807,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentywlinklp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
        mapId: 11811,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentyusdtzlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
        mapId: 11814,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'kUSD-USDtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HEdGi7rq1zgZ68dhAtKMZYKeD3EM5vYdf',
        mapId: 11946,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'wUSDC-USDtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1FaoPYSyT4itmgTncvdshV7SeqqXk9RJXd',
        mapId: 11950,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-hDAO-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
        mapId: 12952,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-ETHtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
        mapId: 12955,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-wWETH-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
        mapId: 12959,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'PLENTY-kUSD-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
        mapId: 12963,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-XTZ-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DMnJvNrFYc8N9Ptxhw3NtqKN7AWqxCpkS',
        mapId: 171168,
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {},
      },
      'Plenty-QUIPU-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
        mapId: 12966,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'wWETH-ETHtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GubVxQVJucUJfJvysvAJKWpLwj3LKuWHn',
        mapId: 12969,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentywraplp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
        mapId: 14096,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-UNO-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
        mapId: 14100,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      Plentykalamlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
        mapId: 14104,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 14,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-SMAK-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
        mapId: 14107,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 10,
        CALL_TYPE: 'FA1.2',
      },
      PlentytzBTClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
        mapId: 14093,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-uUSD-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
        mapId: 15796,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 14,
        CALL_TYPE: 'FA1.2',
      },
      PlentyGiflp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
        mapId: 17061,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-YOU-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
        mapId: 20020,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 15,
        CALL_TYPE: 'FA1.2',
      },
      PlentywUSDTlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
        mapId: 20024,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      PlentywDAIlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
        mapId: 20016,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      PlentyCtezlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
        mapId: 21490,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-wusdc-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
        mapId: 22767,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-you-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
        mapId: 22770,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-udefi-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
        mapId: 22802,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-usdtz-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DQfEUBsaZo43G3j1CbFF9BiWZXS72DuCh',
        mapId: 44781,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wwbtc-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XWRUcvtcgpsETcasdUnNkq9rJeWmz8ihD',
        mapId: 44790,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wusdc-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SXZTX9ZCW5atMnBng1y4pmdS2xi231oMt',
        mapId: 44803,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wusdt-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1KgCx6CYVSyLxfiFdo5hVuMwZBPQy1XZaf',
        mapId: 44806,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wbusd-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1K7GRKUWzfNJ9jzGprb13XDVqqp6GE67ZU',
        mapId: 44819,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wdai-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VLLdUaMfFbJw87b1PaVG1ac6UYM5xzqC5',
        mapId: 44829,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-kalam-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1N3mKQMnWEwt4FB3J4qP2X8LwYgndGDZrn',
        mapId: 44834,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-gif-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1KdPxrn7UTx6CUWxuUBaGx4bVv6ZJKWUWr',
        mapId: 44857,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-ethtz-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GAvdLT9tkpKkRmkuChuL9T83esBtatgKm',
        mapId: 44870,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-quipu-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1C6Q4Mnnpns8vSQy7kJitZNMsED24SMw9m',
        mapId: 44884,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-hdao-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DidqBuSaxGbVaDq2gLVkfh262dYyoGeSH',
        mapId: 44890,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-tzbtc-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TMZjKBvSVKzETZTyRsTxYEu4uZLLs6VpJ',
        mapId: 44904,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wweth-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1BAiXZkKG4R9W7LAKiAQahC8Wg7ZpJBaEs',
        mapId: 44916,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez--lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-uusd-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1BX8F7Xrtsy1tJ91iLovu4nJ8ZyVv4Dac2',
        mapId: 44924,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-smak-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1NeR3ExLddfdAbs5nwpQMm6DqBekyJTc4R',
        mapId: 44946,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 4,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-uno-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1WKWhTNMY4h4eHunbsnJ1oAtDWXCz4qNQm',
        mapId: 45316,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-kusd-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT19bFNh8pZCRtTy7Pi9kpEcb2Yd7KWArAik',
        mapId: 44750,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-wrap-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1MX73dim8HKqQLjTHvkJnpRUp37C1HXdkt',
        mapId: 44767,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-crunch-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1QSA2SMDEKzEnJhGrVGheWb94Ux8Wyfuy6',
        mapId: 44763,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-kdao-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1KmkH1T2CQV2Q5AcMeZy3GHhwNac48FBap',
        mapId: 44912,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-flame-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UfvQiYexnpag6rZ3LMGsn5YFiEHrXrtbq',
        mapId: 44943,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-crdao-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1MQcJCLZL8ASHYGmT5LVzeXV64ruy7RAuH',
        mapId: 44954,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-insta-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GkTwzN2gR6UdBicDSu2JEaRtv5KAPunrj',
        mapId: 44957,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-pxl-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GhzTxzgXgdv1rRCppAB5D4poXiUgp8yoU',
        mapId: 44994,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-paul-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1N93veCzepAPydFGGUhDFiA69PoawasxXi',
        mapId: 46392,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 7,
        CALL_TYPE: 'FA1.2',
      },
      'ctez-doga-lp': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DZ41c1mV12oh8YNXm54JpwUNZ2C5R6VaG',
        mapId: 171789,
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 5,
        CALL_TYPE: 'FA1.2',
      },
    },
  },
  POOLS: {
    mainnet: {
      PLENTY: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CONTRACT: 'KT1QqjR4Fj9YegB37PQEqXUPHmFbhz6VJtwE',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'PLENTY',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended October 1',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CONTRACT: 'KT1UDe1YP963CQSb5xN7cQ1X8NJ2pUyjGw5T',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'PLENTY',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
        ],
      },
      wMATIC: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1TNzH1KiVsWh9kpFrWACrDNnfK4ihvGAZs',
            DEX: 'KT1RsfuBee5o7GtYrdB7bzQ1M6oVgyBnxY4S',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 11,
            DECIMAL: 18,

            CARD_TYPE: 'wMATIC',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1XherecVvrE6X4PV5RTwdEKNzA294ZE9T9',
            DEX: 'KT1RsfuBee5o7GtYrdB7bzQ1M6oVgyBnxY4S',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 11,
            DECIMAL: 18,

            CARD_TYPE: 'wMATIC',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      wLINK: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1JCkdS3x5hTWdrTQdzK6vEkeAdQzsm2wzf',
            DEX: 'KT1Lpysr4nzcFegC9ci9kjoqVidwoanEmJWt',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 10,
            DECIMAL: 18,

            CARD_TYPE: 'wLINK',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1KyxPitU1xNbTriondmAFtPEcFhjSLV1hz',
            DEX: 'KT1Lpysr4nzcFegC9ci9kjoqVidwoanEmJWt',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 10,
            DECIMAL: 18,

            CARD_TYPE: 'wLINK',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      ETHtz: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
            CONTRACT: 'KT1J7v85udA8GnaBupacgY9mMvrb8zQdYb3E',
            DEX: 'KT1Evsp2yA19Whm24khvFPcwimK6UaAJu8Zo',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'ETHtz',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
            CONTRACT: 'KT19asUVzBNidHgTHp8MP31YSphooMb3piWR',
            DEX: 'KT1Evsp2yA19Whm24khvFPcwimK6UaAJu8Zo',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'ETHtz',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended September 2',
            bannerType: 'alert',
          },
        ],
      },
      hDAO: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
            CONTRACT: 'KT1Vs8gqh7YskPnUQMfmjogZh3A5ZLpqQGcg',
            DEX: 'KT1QxLqukyfohPV5kPkw97Rs6cw1DDDvYgbB',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'hDAO',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
            CONTRACT: 'KT1Ga15wxGR5oWK1vBG2GXbjYM6WqPgpfRSP',
            DEX: 'KT1QxLqukyfohPV5kPkw97Rs6cw1DDDvYgbB',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'hDAO',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended September 2',
            bannerType: 'alert',
          },
        ],
      },
      USDtz: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
            CONTRACT: 'KT1K5cgrw1A8WTiizZ5b6TxNdFnBq9AtyQ7X',
            DEX: 'KT1WxgZ1ZSfMgmsSDDcUn8Xn577HwnQ7e1Lb',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'USDtz',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
            CONTRACT: 'KT1MBqc3GHpApBXaBZyvY63LF6eoFyTWtySn',
            DEX: 'KT1WxgZ1ZSfMgmsSDDcUn8Xn577HwnQ7e1Lb',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'USDtz',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      WRAP: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
            CONTRACT: 'KT18oB3x8SLxMJq2o9hKNupbZZ5ZMsgr2aho',
            DEX: 'KT1FG63hhFtMEEEtmBSX2vuFmP87t9E7Ab4t',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 8,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'WRAP',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 11',
            bannerType: 'alert',
          },
        ],
      },
    },
    testnet: {},
  },
  FARMS: {
    mainnet: {
      'uUSD - YOU': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
            CONTRACT: 'KT1KGKzNGX1NDP3hGcipzyqVMCkwWbH76NJU',
            DEX: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / YOU LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=YOU',
            isDualFarm: false,
            message: 'Rewards ended March 14',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'uUSD - wUSDC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
            CONTRACT: 'KT1DfYVe4CaE9S6Sm3SEfhFYVZ9XzJbkQDqs',
            DEX: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / wUSDC LP',
            TOKEN_DECIMAL: 9,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=wUSDC',
            isDualFarm: false,
            message: 'Rewards ended January 28',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'uUSD - uDEFI': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
            CONTRACT: 'KT1RENb4rWNFPP5QJSYT4rRGGsk1tPgLLwu2',
            DEX: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / uDEFI LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=uDEFI',
            isDualFarm: false,
            message: 'Rewards ended March 14',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - cTez': {
        active: [
          {
            LP_TOKEN: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
            CONTRACT: 'KT1MfMMsYX34Q9cEaPtk4qkQ6pojA7D2nsgr',
            DEX: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / cTez LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=ctez',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [],
      },
      'PLENTY - XTZ': {
        active: [
          {
            LP_TOKEN: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            CONTRACT: 'KT1JQAZqShNMakSNXc2cgTzdAWZFemGcU6n1',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink:
              'https://quipuswap.com/invest/add-liquidity/KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [
          {
            LP_TOKEN: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            CONTRACT: 'KT1BfQLAsQNX8BjSBzgjTLx3GTd3qhwLoWNz',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'CTEZ - DOGA': {
        active: [
          {
            LP_TOKEN: 'KT1DZ41c1mV12oh8YNXm54JpwUNZ2C5R6VaG',
            CONTRACT: 'KT1E6qsUB3EKEDs4S3yQCyr39zVjsjBtFjEp',
            DEX: 'KT1NLxs6rSYaJikjuCRVbVfg3p9ehfVuQgHC',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'CTEZ / DOGA LP',
            TOKEN_DECIMAL: 5,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=doga',

            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'kUSD - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1J5HNUXnQwonSam3kmDQ5WbhynPPpVtgdN',
            CONTRACT: 'KT1HpncMNCYt1ZskHzZeFqHFgfM8ZEr8xd3f',
            DEX: 'KT1HgFcDE8ZXNdT1aXXKpMbZc6GkUS2VHiPo',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'kUSD / USDC.e LP',
            TOKEN_DECIMAL: 12, //lptokendecimal
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=kUSD&tokenB=USDC.e',

            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'uUSD - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1Ah4Fe2hjcRdVCiCQDhRDZ2vDYLdYNjY51',
            CONTRACT: 'KT1GQ7UTHFA8gTH3uTzDnb1znDDBfJ7eXCUJ',
            DEX: 'KT1Ji4hVDeQ5Ru7GW1Tna9buYSs3AppHLwj9',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / USDC.e LP',
            TOKEN_DECIMAL: 9,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=USDC.e',

            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'USDtz - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1P9GyfYrW9EZ8m3jej2cMu49fpmEbYa2h5',
            CONTRACT: 'KT1BUqUTY4r8ByQ5psHeQS46zuXu7JUvvWHH',
            DEX: 'KT1995R6hTYzXjV52mGtf6MboPRhzbJBJe6q',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'USDtz / USDC.e LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=USDtz&tokenB=USDC.e',

            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'EURL - agEUR.e': {
        active: [
          {
            LP_TOKEN: 'KT1X4fkZ1f3uCpBqC1HZemsSNFbqm6bkpgfb',
            CONTRACT: 'KT1Ne85tY7r6HuyRExQNb2V5zzE9u4ppts6m',
            DEX: 'KT1Nrbzh6WqwDgJ513qTEQqnmJTPpRv6ZKr5',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'EURL / agEUR.e LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=EURL&tokenB=agEUR.e',

            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'USDt - ctez': {
        active: [
          {
            LP_TOKEN: 'KT1BaGrWEPYsEyQEftyegLrGEqY2psAJh5GX',
            CONTRACT: 'KT1DkNdbMDy3bZcFwJQi71B6Lb6H3VmSjAx5',
            DEX: 'KT1D1NcffeDR3xQ75fUFoJXZzD6WQp96Je3L',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'USDt / CTEZ PNLP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 6,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=USDt&tokenB=ctez',

            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'WMATIC.p - ctez': {
        active: [
          {
            LP_TOKEN: 'KT1FCXZu12H6G5Ujw8jPdektkuW5kWHEn49D',
            CONTRACT: 'KT1XAPCdjYzt5Q1SRrUqM8ZC4fekasgbEnju',
            DEX: 'KT1SmLuyw2gfKme6EkUnEQpamCGMr1mDQVeK',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WMATIC.p / CTEZ PNLP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=WMATIC.p&tokenB=ctez',
            message: '😍 New farm',
            bannerType: 'info',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'WETH.p - ctez': {
        active: [
          {
            LP_TOKEN: 'KT1B5MPrS2KTTJ3VufVsr1RyMxuY1k5nhSdY',
            CONTRACT: 'KT1QCt7fgdTRr6EMKTqVE7F2JLLQgdvQUTL1',
            DEX: 'KT1Nta8VsKdm6J9XBYknKeeDciQFopKkkMAg',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WETH.p / CTEZ PNLP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=WETH.p&tokenB=ctez',
            message: '😍 New farm',
            bannerType: 'info',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'WMATIC.p - MATIC.e': {
        active: [
          {
            LP_TOKEN: 'KT1VBQbNYvD5VGRRMfgovNehtoevXeTMyQWm',
            CONTRACT: 'KT1Eq23cXLKnaGPPkR7hdMHV44GHX356L9Xj',
            DEX: 'KT1WvoCeiWbSMHJLBGXw4q5W1i1U876GqjAe',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WMATIC.p / MATIC.e PNLP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=WMATIC.p&tokenB=MATIC.e',
            message: '😍 New farm',
            bannerType: 'info',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'WETH.p - WETH.e': {
        active: [
          {
            LP_TOKEN: 'KT1JEwoaaCHwof9ERqqZzpSuKXwwgCwV6bhV',
            CONTRACT: 'KT1VQBmo7brLt5AR6nx39stzP677mR7s52Mv',
            DEX: 'KT1QoXQBq7L1fYZXXgRogPbh112sfL1Rp4h2',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WETH.p / WETH.e PNLP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=WETH.p&tokenB=WETH.e',
            message: '😍 New farm',
            bannerType: 'info',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'BUSD.e - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1G76qEBDqmAXLCCVg4ZuNbrENsxFe8aECe',
            CONTRACT: 'KT1HK91NY9uWa685DAYRH8ywNMaN52D8Z8NS',
            DEX: 'KT1LutmpUK8TDkA19tLCQNr7Eh7kbEsP3uiP',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'BUSD.e / USDC.e PNLP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=BUSD.e&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'kUSD - USDt': {
        active: [
          {
            LP_TOKEN: 'KT1JaYzZooZmq9JP9FScjLShbUco8Pn8Ckct',
            CONTRACT: 'KT1EQ9RzjVNozWTTbuHPFsoaHDFiP4nMBRGA',
            DEX: 'KT1XfZi2K6gVM3wXDyeCwMhaT88JueVEAB8r',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'kUSD / USDt LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=kUSD&tokenB=USDt',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'uUSD - USDt': {
        active: [
          {
            LP_TOKEN: 'KT1Doix5iZZsQzKY3ANDkoca6GfCtJbXY9sk',
            CONTRACT: 'KT1BQgGMe4PECmbFK1kyEdJmoXyJeCWf8XzC',
            DEX: 'KT19XPttmgtKtnpKXbcn41AqbcUqX7cweAmu',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / USDt LP',
            TOKEN_DECIMAL: 9,
            TYPE: 'FA1.2',
            LP_DECIMAL: 9,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=USDt',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },

      'USDT.e - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1CDeAxaiqbA5aMkPMmqqYXxqgfFwocJHza',
            CONTRACT: 'KT1PJJ7JgjJyc5VzHz2kPfUikauzt6r1QfJw',
            DEX: 'KT1PU4Ce89RyF1itwYxknVNcvtUWKdKy6rvQ',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'USDT.e / USDC.e LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=USDT.e&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'tzBTC - WBTC.e': {
        active: [
          {
            LP_TOKEN: 'KT1HJbndQjBEhAqR3Vj3NaP3RwFGudJf5YP4',
            CONTRACT: 'KT1SzTf3uBZAx1DnPbNTg29H7dVPbuzkGJNo',
            DEX: 'KT1Ws4qpXFVRKctB5mJcahFVcpcUi2WbxWM3',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'tzBTC / WBTC.e LP',
            TOKEN_DECIMAL: 8,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=tzBTC&tokenB=WBTC.e',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'DAI.e - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1TupjqgajsoArMKK3ifmNveKhU2XpTJrH8',
            CONTRACT: 'KT1Kx45mQaZMEtFrbd6ap814NoH7cQTqo33j',
            DEX: 'KT1Hxn43imdKTWXEZsLVdoUsS1R4hWTSXgDs',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'DAI.e / USDC.e LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=DAI.e&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'ETHtz - WETH.e': {
        active: [
          {
            LP_TOKEN: 'KT19ga4Vz26GRBdn8oibR4WFF7aDF4oE9Ch3',
            CONTRACT: 'KT18qMYMqPv8J17wNoQigD1C66eyhf9b5s2e',
            DEX: 'KT1BKcLyaaNf2SHfAivBFBqdFoYTjuc78Aff',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ETHtz / WETH.e LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ETHtz&tokenB=WETH.e',
            isDualFarm: false,
            farmType: 'veStableAMM',
          },
        ],
        inactive: [],
      },
      'WBTC.e - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT1VRAYxpCo5xHYmBLYmMAWxXoVYCVgpeuak',
            CONTRACT: 'KT1CZkFknknf2NdV48VMRw9cp97n9T7oRApi',
            DEX: 'KT1KapPE9E65fBozpJqsuCgaLPytvKQduPvF',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WBTC.e / CTEZ LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 7,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=WBTC.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'USDC.e - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT1XpLzv4tqqsf2aLneDZgEeNFhKkH8JBGXv',
            CONTRACT: 'KT1P3MUj7ZY1pak1szf41vxtuqz7AVMgCLhj',
            DEX: 'KT1Dhy1gVW3PSC9cms9QJ7xPMPPpip2V9aA6',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'USDC.e / CTEZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 6,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'EURL - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT1HFMKcdrLBH5RjC4waPVNprn8aywDEgwog',
            CONTRACT: 'KT1XaHdsQAh4EZkjgvwRFBkXpqh6rFAP2cXM',
            DEX: 'KT1LqEgLikLE2obnyzgPJA6vMtnnG5agXVCn',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'EURL / CTEZ PNLP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 6,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=EURL&tokenB=ctez',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },

      'agEUR.e - USDC.e': {
        active: [
          {
            LP_TOKEN: 'KT1CDK8VMGy94sH4Mb6YzMmJX7HTsFqYTFee',
            CONTRACT: 'KT1JZPj4AwDSNNfmjWNJU3qqxobdAwt1KS1b',
            DEX: 'KT1KZ93C79SqZw5CLUwwbLPxA5CwVGyKD365',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'agEUR.e / USDC.e PNLP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=agEUR.e&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },

      'WETH.e - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT1SDugcNjEbNQe55TdQppiRP1wVDbmxHuF1',
            CONTRACT: 'KT1U36NnQrpQ2vVHde6PtTPKEpNXHrEZ84ek',
            DEX: 'KT1Qs52cCz1gLK8LYi6cZJm7YjExg6MYLdkG',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'WETH.e / CTEZ LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=USDC.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'MATIC.e - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT18rVWWn2LMo2Hpy6KNhkhGgks21g11dxVv',
            CONTRACT: 'KT1RvJGhrRf3D5ymy1jkof6wvqE57u2qc6J1',
            DEX: 'KT1DiaHKX1jveXp1HGLE8X3MdxA13SLDRT7S',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'MATIC.e / CTEZ LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=MATIC.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'LINK.e - CTEZ': {
        active: [
          {
            LP_TOKEN: 'KT1WcS1cdwcbT3yyuYA6uzsYdZxBax5cGjdR',
            CONTRACT: 'KT1CGj3hNZniZELy2VREDYyTpYEfvJsqKQ6a',
            DEX: 'KT1GGaeBoJNxD3ieHtRiJhmcMguVLroksBGn',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'LINK.e / CTEZ LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=ctez&tokenB=LINK.e',
            isDualFarm: false,
            farmType: 'veAMM',
          },
        ],
        inactive: [],
      },
      'KALAM - XTZ': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            CONTRACT: 'KT1DjDZio7k2GJwCJCXwK82ing3n51AE55DW',
            DEX: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'KALAM / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA2',
            TOKEN_ID: 0,
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
            DECIMAL: 18,
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 22',
            bannerType: 'alert',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wBUSD': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
            CONTRACT: 'KT1KJhxkCpZNwAFQURDoJ79hGqQgSC9UaWpG',
            DEX: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wBUSD LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wBUSD',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wUSDC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
            CONTRACT: 'KT1Kp3KVT4nHFmSuL8bvETkgQzseUYP3LDBy',
            DEX: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wUSDC LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDC',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wWBTC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
            CONTRACT: 'KT1M82a7arHVwcwaswnNUUuCnQ45xjjGKNd1',
            DEX: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wWBTC LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wWBTC',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wMATIC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
            CONTRACT: 'KT1UP9XHQigWMqNXYp9YXaCS1hV9jJkCF4h4',
            DEX: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wMATIC LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wMATIC',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wLINK': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
            CONTRACT: 'KT1UqnQ6b1EwQgYiKss4mDL7aktAHnkdctTQ',
            DEX: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wLINK LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wLINK',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - USDtz': {
        active: [
          {
            LP_TOKEN: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
            CONTRACT: 'KT1VCrmywPNf8ZHH95HKHvYA4bBQJPa8g2sr',
            DEX: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / USDtz LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=USDtz',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [],
      },
      'PLENTY - hDAO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
            CONTRACT: 'KT1W3DtcPXbD7MMmtUdk3F352G6CYFSpwUUS',
            DEX: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / hDAO LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=hDAO',
            isDualFarm: false,
            farmType: 'oldAMM',
            message: 'Rewards ended July 20',
            bannerType: 'alert',
          },
        ],
      },
      'PLENTY - ETHtz': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
            CONTRACT: 'KT1EVfYFoSpte3PnE4tPoWuj1DhNPVQwrW5Y',
            DEX: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / ETHtz LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=ETHtz',
            isDualFarm: false,
            farmType: 'oldAMM',
            message: 'Rewards ended July 20',
            bannerType: 'alert',
          },
        ],
      },
      'PLENTY - wWETH': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
            CONTRACT: 'KT1CBh8BKFV6xAH42hEdyhkijbwzYSKW2ZZC',
            DEX: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wWETH LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wWETH',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - kUSD': {
        active: [
          {
            LP_TOKEN: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
            CONTRACT: 'KT1MmAy4mSbZZVzPoYbK3M4z3GWUo54UTiQR',
            DEX: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / kUSD LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=kUSD',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [],
      },
      'PLENTY - QUIPU': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
            CONTRACT: 'KT1FsMiweyRTog9GGNC22hiMTFVRPrGs3eto',
            DEX: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / QUIPU LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            message: 'Rewards ended November 29',
            bannerType: 'alert',
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=QUIPU',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - WRAP': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
            CONTRACT: 'KT1K9kLuhq9AJjDAgbJdKGBiP9927WsRnjP6',
            DEX: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / WRAP LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=WRAP',
            isDualFarm: false,
            farmType: 'oldAMM',
            message: 'Rewards ended July 20',
            bannerType: 'alert',
          },
        ],
      },
      'PLENTY - UNO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
            CONTRACT: 'KT1CWNVmHs6RRbLzwA3P19h7Wa9smnDrAgpS',
            DEX: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / UNO LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            message: 'Rewards ended November 29',
            bannerType: 'alert',
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=UNO',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - SMAK': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
            CONTRACT: 'KT1VwZPZ4bcPQYS1C4yRvmU4giQDXhEV81WD',
            DEX: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / SMAK LP',
            TOKEN_DECIMAL: 10,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            message: 'Rewards ended November 29',
            bannerType: 'alert',
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=SMAK',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - KALAM': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
            CONTRACT: 'KT1UTvMuyRggQe9q1hrh7YLh7vxffX2egtS6',
            DEX: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / KALAM LP',
            TOKEN_DECIMAL: 14,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=KALAM',
            isDualFarm: false,
            farmType: 'oldAMM',
            message: 'Rewards ended July 20',
            bannerType: 'alert',
          },
        ],
      },
      'PLENTY - tzBTC': {
        active: [
          {
            LP_TOKEN: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
            CONTRACT: 'KT1RwFV1xQU2E9TsXe1qzkdwAgFWaKk8bfAa',
            DEX: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / tzBTC LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=tzBTC',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [],
      },
      'PLENTY - uUSD': {
        active: [
          {
            LP_TOKEN: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
            CONTRACT: 'KT1HSYQ9NLTQufuvNUwMhLY7B9TX8LDUfgsr',
            DEX: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / uUSD LP',
            TOKEN_DECIMAL: 14,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=uUSD',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
        inactive: [],
      },
      'PLENTY - GIF': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
            CONTRACT: 'KT1UH21n4iwXu7gGrh34RKZfRewpcgQLdbXq',
            DEX: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            dualInfo: {
              tokenFirst: {
                symbol: 'PLENTY',
                tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                tokenDecimal: 18,
                tokenType: 'FA1.2',
                tokenId: 0,
                rewardContract: 'KT1KPSKAsmM9k28Lsbk1mmrujHpCnQHxKhKS',
              },
              tokenSecond: {
                symbol: 'GIF',
                tokenContract: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
                tokenDecimal: 9,
                tokenType: 'FA2',
                tokenId: 0,
                rewardContract: 'KT1LQS3kyhaaW6thmW96anY71gZ92ECGU7Ja',
              },
            },
            CARD_TYPE: 'PLENTY / GIF Dual LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            message: 'Rewards ended October 19',
            bannerType: 'alert',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=GIF',
            isDualFarm: true,
            farmType: 'oldAMM',
          },
        ],
      },
      'CTEZ - TEZ': {
        active: [
          {
            LP_TOKEN: 'KT1DMnJvNrFYc8N9Ptxhw3NtqKN7AWqxCpkS',
            CONTRACT: 'KT1WzUmmF98aQdpKnWigkg3SnJiL1s2Fj6QQ',
            DEX: 'KT1CAYNQGvYSF5UvHK21grMrKpe2563w9UcX',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            dualInfo: {
              tokenFirst: {
                symbol: 'PLENTY',
                tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                tokenDecimal: 18,
                tokenType: 'FA1.2',
                tokenId: 0,
                rewardContract: 'KT1QkadMTUTDxyNiTaz587ssPXFuwmWWQzDG',
              },
              tokenSecond: {
                symbol: 'TEZ',
                tokenContract: '',
                tokenDecimal: 6,
                tokenType: 'XTZ',
                tokenId: 0,
                rewardContract: 'KT1PxZCPGoxukDXq1smJcmQcLiadTB6czjCY',
              },
            },
            CARD_TYPE: 'CTEZ / TEZ Dual PNLP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=tez',
            isDualFarm: true,
            message: '😍 Dual rewards',
            bannerType: 'info',
            farmType: 'xtz',
          },
        ],
        inactive: [],
      },
      'PLENTY - YOU': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
            CONTRACT: 'KT1MkXtVBuCKtxqSh7APrg2d7ThGBmEf4hnw',
            DEX: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / YOU LP',
            TOKEN_DECIMAL: 15,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=YOU',
            isDualFarm: false,
            message: 'Rewards ended December 23',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wDAI': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
            CONTRACT: 'KT1FJzDx9AwbuNHjhzQuUxxKUMA9BQ7DVfGn',
            DEX: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wDAI LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wDAI',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'PLENTY - wUSDT': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
            CONTRACT: 'KT1S4XjwGtk55TmsMqSdazEMrH4pGA3NMXhz',
            DEX: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wUSDT LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended May 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - kUSD': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT19bFNh8pZCRtTy7Pi9kpEcb2Yd7KWArAik',
            CONTRACT: 'KT1L8N5RZg4CM2VSnuC8t1CGLiQpzVoN6P1u',
            DEX: 'KT1X1nkqJDR1UHwbfpcnME5Z7agJLjUQNguB',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / kUSD LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - USDtz': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1DQfEUBsaZo43G3j1CbFF9BiWZXS72DuCh',
            CONTRACT: 'KT1MeeKpbSaAP9YDb6B7qBDvEGZq274715s2',
            DEX: 'KT1PWAXfPatPWBNJUxTHin4ECin1kYJHHnsr',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / USDtz LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wUSDC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1SXZTX9ZCW5atMnBng1y4pmdS2xi231oMt',
            CONTRACT: 'KT1V71rxAhuHCggnBUXfceBM6RpHzUUJMYyK',
            DEX: 'KT1PZpbmKtGE6ZyYeF8entfjuGGT7CRUCF5g',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wUSDC LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wUSDT': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1KgCx6CYVSyLxfiFdo5hVuMwZBPQy1XZaf',
            CONTRACT: 'KT1PYtm173zVdPEMoNecUBRhqgFbdVHPDj3p',
            DEX: 'KT1AfTwam4bNPawLv4bWqSj9GsVDPsf5F5bQ',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wUSDT LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wBUSD': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1K7GRKUWzfNJ9jzGprb13XDVqqp6GE67ZU',
            CONTRACT: 'KT1MCtUoNZTpGsPNyEvCZ88i6aRVow91axPr',
            DEX: 'KT1GN7PHpFsH43ypFyE2hxNMdxqTuiCGm4Pm',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wBUSD LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wDAI': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1VLLdUaMfFbJw87b1PaVG1ac6UYM5xzqC5',
            CONTRACT: 'KT1ELuj8v8nB3Me7dEyjoxkNtjr6ywWXFWYK',
            DEX: 'KT1L5qd9xPWjiEX6ZNovhaty228ASg6jCE5p',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wDAI LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - KALAM': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1N3mKQMnWEwt4FB3J4qP2X8LwYgndGDZrn',
            CONTRACT: 'KT1HmigpaNyaxx9Zvf7zEPGgqd8bdLTnrgdU',
            DEX: 'KT1HuZVo3ZKRhkaHDsYXZ2fvmVfkY1FrbEDj',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / KALAM LP',
            TOKEN_DECIMAL: 8,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - GIF': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1KdPxrn7UTx6CUWxuUBaGx4bVv6ZJKWUWr',
            CONTRACT: 'KT1BaDfS92GJRDpBhrZh2a29ZyNmEjaYApkK',
            DEX: 'KT1VnFPwDGJRt5ufMgcWafM2WWcZCC77gpT3',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / GIF LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - ETHtz': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1GAvdLT9tkpKkRmkuChuL9T83esBtatgKm',
            CONTRACT: 'KT1U2XwrY1oTfxzrcb1ZrQuFrUJTQWAJaHWt',
            DEX: 'KT1GSYhwanehtwCK3NPfkMFbD1bNQmvosbqL',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / ETHtz LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - QUIPU': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1C6Q4Mnnpns8vSQy7kJitZNMsED24SMw9m',
            CONTRACT: 'KT1L71FDbzYwe9bxY4MnzU3ZUgUzbe3V588Y',
            DEX: 'KT1Ss8rb1UFVqG2LYEU5g4NEbK5SqW5Xadwp',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / QUIPU LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - hDAO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1DidqBuSaxGbVaDq2gLVkfh262dYyoGeSH',
            CONTRACT: 'KT1BZ8Napzts13b9kLGBWCwhMmx6zmAzRMkG',
            DEX: 'KT1RSxExbbRwQdYBpRHWup9vxvw1Hp25vFVM',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / hDAO LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - kDAO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1KmkH1T2CQV2Q5AcMeZy3GHhwNac48FBap',
            CONTRACT: 'KT1DornVnLZWu3VaJnDLB2RubSFSPBZaKq4W',
            DEX: 'KT1SP9WsMeLFTiHBXqEumTASYbF3CXh22aVV',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / kDAO LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wWETH': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1BAiXZkKG4R9W7LAKiAQahC8Wg7ZpJBaEs',
            CONTRACT: 'KT1JfEmUp9w2n9aWJweKpEWAESRjrCywcW3M',
            DEX: 'KT1WwqM2MH38PSQbECxPngBVLSbqJUHzCFAH',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wWETH LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - uUSD': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1BX8F7Xrtsy1tJ91iLovu4nJ8ZyVv4Dac2',
            CONTRACT: 'KT1VutBNeCNEHtk6jVkY8v3FE848GNsxjT76',
            DEX: 'KT1Rx3pQzsn4FBuuYhcWsqUS7vWFx3ktqSWD',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / uUSD LP',
            TOKEN_DECIMAL: 9,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - FLAME': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1UfvQiYexnpag6rZ3LMGsn5YFiEHrXrtbq',
            CONTRACT: 'KT1HsdhTQqha8k6WrvhD9d6CQhHGb3XCtLub',
            DEX: 'KT1Bp3JAeeMP4GvJaspFw6zah6WydqqqHPNw',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / FLAME LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - SMAK': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1NeR3ExLddfdAbs5nwpQMm6DqBekyJTc4R',
            CONTRACT: 'KT1MSWvuDNcjnxP3mBK4mePLpVLfrjZ3i2nH',
            DEX: 'KT1Qg4FmXDmViQgyYLT5QkgZQSmPKvKjZbzn',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / SMAK LP',
            TOKEN_DECIMAL: 4,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - crDAO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1MQcJCLZL8ASHYGmT5LVzeXV64ruy7RAuH',
            CONTRACT: 'KT1BfAAVACnEAb3CMMxPLb1VdDnyuNq5AWEc',
            DEX: 'KT1JkHBYp3Pq4TRSkFdc2CRoXFgDkJrfCqUe',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / crDAO LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - PXL': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1GhzTxzgXgdv1rRCppAB5D4poXiUgp8yoU',
            CONTRACT: 'KT1HpcPqU2atG75bpSy6hUzuWdULi7Kx9eaw',
            DEX: 'KT1WR19YA8Go6GbTvtrMpUEEGSuQYxgz2ZVW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / PXL LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - UNO': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1WKWhTNMY4h4eHunbsnJ1oAtDWXCz4qNQm',
            CONTRACT: 'KT1Xt9HvkrbzYtaYhD9im37hE69Zit5bvhPw',
            DEX: 'KT1XLFZ2RS8vCUmHwBG39mq7zELhnLKn9JUz',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / UNO LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - WRAP': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1MX73dim8HKqQLjTHvkJnpRUp37C1HXdkt',
            CONTRACT: 'KT1LPhjjaFaqcgynzewegLckJUmpQQzm1xj2',
            DEX: 'KT19Qe4KbEVAiaVeNsgo9Tkqa6qvZho8c4W5',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / WRAP LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - wWBTC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1XWRUcvtcgpsETcasdUnNkq9rJeWmz8ihD',
            CONTRACT: 'KT1VmvPo8bLYh1xVC9TpzwWtrczjzEMS2mEk',
            DEX: 'KT1XPUvZHc1YKTJYnGbB755V95hDgvS1qQYf',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / wWBTC LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - tzBTC': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1TMZjKBvSVKzETZTyRsTxYEu4uZLLs6VpJ',
            CONTRACT: 'KT1SQ4fqsxrKwN5mqCi2yNLvb99S6sRGWGZZ',
            DEX: 'KT1Wryxte8NnVCm569yb8dcSPmvFBVs4ufD6',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / tzBTC LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - PAUL': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1N93veCzepAPydFGGUhDFiA69PoawasxXi',
            CONTRACT: 'KT1K3NERNpLxRmREpmjqt7LsvEr2SbC9Cs6o',
            DEX: 'KT1LixgLzdK4nseeD6MmmVpokuw9CvpVX9KW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / PAUL LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - INSTA': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1GkTwzN2gR6UdBicDSu2JEaRtv5KAPunrj',
            CONTRACT: 'KT1UwhcK52Pk3VsFyFmFocVzSmKY8wRHGdDC',
            DEX: 'KT1EnESbHxmzd2XeENyYNege4L5TKxeatJk9',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / INSTA LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
      'ctez - CRUNCH': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1QSA2SMDEKzEnJhGrVGheWb94Ux8Wyfuy6',
            CONTRACT: 'KT1CoCHHKAUE8vobDwaWQDDBzrBkasCuzkwi',
            DEX: 'KT1WZztBneC9NrhVqvo9yXq8St7p8c1JVTxm',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'ctez / CRUNCH LP',
            TOKEN_DECIMAL: 7,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type5',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
            message: 'Rewards ended December 31',
            bannerType: 'alert',
            farmType: 'oldAMM',
          },
        ],
      },
    },
    testnet: {},
  },
  PONDS: {
    mainnet: {
      KALAM: {
        active: [],
        inactive: [
          {
            REWARD_TOKEN: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
            STAKING_TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            STAKING_CONTRACT: 'KT1WfLprabHVTnNhWFigmopAduUpxG5HKvNf',
            STAKING_TOKEN_DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            REWARD_TOKEN_DEX: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            STAKING_TOKEN_DECIMAL: 18,
            REWARD_TOKEN_DECIMAL: 10,
            TYPE: 'FA1.2',
            CARD_TYPE: 'KALAM',
          },
        ],
      },
      WRAP: {
        active: [],
        inactive: [
          {
            REWARD_TOKEN: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
            STAKING_TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            STAKING_CONTRACT: 'KT1GotpjdBaxt2GiMFcQExLEk9GTfYo4UoTa',
            STAKING_TOKEN_DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            REWARD_TOKEN_DEX: 'KT1FG63hhFtMEEEtmBSX2vuFmP87t9E7Ab4t',
            STAKING_TOKEN_DECIMAL: 18,
            REWARD_TOKEN_DECIMAL: 8,
            TYPE: 'FA1.2',
            CARD_TYPE: 'WRAP',
          },
        ],
      },
    },
    testnet: {},
  },
  withdrawalFeeDistribution: {
    type1: [
      { block: 8192, rate: 25, duration: '< 3 days' },
      { block: 16384, rate: 12.5, duration: '< 6 days' },
      { block: 24576, rate: 10, duration: '< 9 days' },
      { block: 24576, rate: 4, duration: '> 9 days' },
    ],
    type2: [
      { block: 24576, rate: 4, duration: '< 9 days' },
      { block: 24576, rate: 0, duration: '> 9 days' },
    ],
    type3: [{ block: 0, rate: 0, duration: '> 0 days' }],
    type4: [
      { block: 8192, rate: 25, duration: '< 3 days' },
      { block: 16384, rate: 12.5, duration: '< 6 days' },
      { block: 24576, rate: 10, duration: '< 9 days' },
      { block: 24576, rate: 2, duration: '> 9 days' },
    ],
    type5: [
      { block: 20160, rate: 4, duration: '< 7 days' },
      { block: 40320, rate: 2.5, duration: '< 14 days' },
      { block: 40320, rate: 0.5, duration: '> 14 days' },
    ],
  },
  xPlenty: {
    testnet: {
      plentyTokenContract: {
        address: 'KT1PHbg4Dqmg9zWwuWQjo4dDTsgJ5svdyXdH',
        balancesMapId: 22612,
        decimal: 18,
      },
      xPlentyTokenContract: {
        address: 'KT1PP3YaYF4iNTyryjjKe5WrqnnMbQPLqd4E',
        balancesMapId: 40509,
        decimal: 18,
      },
      rewardManager: {
        address: 'KT1M1uU6br1ijvZVGe6FYwqf3kHK4GmUfDso',
      },
      xPlentyCurve: {
        address: 'KT1Luq2wvCFJ8zDCJmqWsyXLXeffhwcTExJe',
        bigMapExpression: 'exprudLL27ekHKb2ZJCTRomMHjKqPK8CmJjDpk2cY2shwagF3woAnn',
      },
    },
    mainnet: {
      plentyTokenContract: {
        address: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        balancesMapId: 3943,
        decimal: 18,
      },
      xPlentyTokenContract: {
        address: 'KT1Rpviewjg82JgjGfAKFneSupjAR1kUhbza',
        balancesMapId: 18153,
        decimal: 18,
      },
      rewardManager: {
        address: 'KT1MCgouivQ2rzam5hA2gqF1eMtY5i6ndJvT',
      },
      xPlentyCurve: {
        address: 'KT1PxkrCckgh5fA5v2cZEE2bX5q2RV1rv8dj',
        bigMapExpression: 'exprudkkDPkJ6KmqHyZ1VT7aZHYji3s7FnkKayYkDBrmUemSbsFwSJ',
      },
    },
  },
  GOVERNANCE: {
    address: 'KT1CAeUQfyMnBVqkt4QcztmjsjAWYzK6ZPYQ',
    mapId: 55983,
  },
  NETWORK: 'mainnet',
  WALLET_NETWORK: 'mainnet',
  ADMIN_ADDRESS: 'KT1GpTEq4p2XZ8w9p5xM7Wayyw5VR7tb3UaW',
  BURNER: 'tz1ZnK6zYJrC9PfKCPryg9tPW6LrERisTGtg',
  WRAPPED_ASSETS: {
    testnet: {
      wWBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 19,
        TOKEN_DECIMAL: 8,
        REF_TOKEN: 'WBTC.e',
        READ_TYPE: 'FA2',
      },
      wWETH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 20,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'WETH.e',
        READ_TYPE: 'FA2',
      },
      wBUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'BUSD.e',
        READ_TYPE: 'FA2',
      },
      wUSDC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1FcWdvGqggfaY4AuepEj2GjHBmk5jP33jE',
        mapId: 84170,
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'USDC.e',
        READ_TYPE: 'FA2',
      },
      wUSDT: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 18,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'USDT.e',
        READ_TYPE: 'FA2',
      },
      wMATIC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1FcWdvGqggfaY4AuepEj2GjHBmk5jP33jE',
        mapId: 84170,
        TOKEN_ID: 2,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'MATIC.e',
        READ_TYPE: 'FA2',
      },
      wLINK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 10,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'LINK.e',
        READ_TYPE: 'FA2',
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VoHhkb6wXnoPDvcpbnPFYGTcpJaPfRoEh',
        mapId: 162869,
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'DAI.e',
        READ_TYPE: 'FA2',
      },
      'WBTC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 4,
        TOKEN_DECIMAL: 8,
        REF_TOKEN: 'wWBTC',
        READ_TYPE: 'FA2',
      },
      'WETH.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wWETH',
        READ_TYPE: 'FA2',
      },
      'BUSD.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wBUSD',
        READ_TYPE: 'FA2',
      },
      'USDC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AXQ7BgYgi8KBVhF3NmcdBNNTwyqXDC2vT',
        mapId: 84183,
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wUSDC',
        READ_TYPE: 'FA2',
      },
      'USDT.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 3,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'wUSDT',
        READ_TYPE: 'FA2',
      },
      'MATIC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AXQ7BgYgi8KBVhF3NmcdBNNTwyqXDC2vT',
        mapId: 84183,
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wMATIC',
        READ_TYPE: 'FA2',
      },
      'LINK.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 6,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wLINK',
        READ_TYPE: 'FA2',
      },
      'DAI.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1EZBn43coqL6xfT5xL6e49nhEPLp9B8m4n',
        mapId: 162915,
        TOKEN_ID: 7,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wDAI',
        READ_TYPE: 'FA2',
      },
    },
    mainnet: {
      wWBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 19,
        TOKEN_DECIMAL: 8,
        REF_TOKEN: 'WBTC.e',
        READ_TYPE: 'FA2',
      },
      wWETH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 20,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'WETH.e',
        READ_TYPE: 'FA2',
      },
      wBUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'BUSD.e',
        READ_TYPE: 'FA2',
      },
      wUSDC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 17,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'USDC.e',
        READ_TYPE: 'FA2',
      },
      wUSDT: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 18,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'USDT.e',
        READ_TYPE: 'FA2',
      },
      wMATIC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 11,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'MATIC.e',
        READ_TYPE: 'FA2',
      },
      wLINK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 10,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'LINK.e',
        READ_TYPE: 'FA2',
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'DAI.e',
        READ_TYPE: 'FA2',
      },
      'WBTC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 8,
        REF_TOKEN: 'wWBTC',
        READ_TYPE: 'FA2',
      },
      'WETH.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wWETH',
        READ_TYPE: 'FA2',
      },
      'BUSD.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 7,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wBUSD',
        READ_TYPE: 'FA2',
      },
      'USDC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 2,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'wUSDC',
        READ_TYPE: 'FA2',
      },
      'USDT.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 3,
        TOKEN_DECIMAL: 6,
        REF_TOKEN: 'wUSDT',
        READ_TYPE: 'FA2',
      },
      'MATIC.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 4,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wMATIC',
        READ_TYPE: 'FA2',
      },
      'LINK.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wLINK',
        READ_TYPE: 'FA2',
      },
      'DAI.e': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UsSfaXyqcjSVPeiD7U1bWgKy3taYN7NWY',
        mapId: 175082,
        TOKEN_ID: 6,
        TOKEN_DECIMAL: 18,
        REF_TOKEN: 'wDAI',
        READ_TYPE: 'FA2',
      },
    },
  },
  WRAPPED_ASSETS_SWAP_CONTRACT: {
    testnet: 'KT1QeredMEzkQtkDuL7SbEQ3VTicPLX2qJfU',
    mainnet: 'KT1R5xLx7DRXWwEWGxo6f4rVpZoBQ2CUdkDv',
  },
  BRIDGES_INDEXER_LINKS: {
    testnet: {
      RINKEBY: 'https://bridgeindexer.plentydefi.com/v1/configuration',
      MUMBAI: 'https://bridgepolygontestnetindexer.plentydefi.com/configuration',
    },
    mainnet: {
      ETHEREUM: 'https://bridgemainnetindexer.plentydefi.com/configuration',
      POLYGON: 'https://polygon-bridge.indexer.plenty.network/v1/configuration',
    },
  },
  EXPLORER_LINKS: {
    RINKEBY: 'https://rinkeby.etherscan.io/tx/',
    ETHEREUM: 'https://etherscan.io/tx/',
    TEZOS: 'https://tzkt.io/',
    MUMBAI: 'https://mumbai.polygonscan.com/',
    POLYGON: 'https://polygonscan.com/',
  },
};

export default Config;
